import React from "react";
import { PageHeader } from "../shared/PageHeader";
import { MaintenanceServices } from "./MaintenanceServices";
import { MaintenanceServicesDescription } from "./MaintenanceServicesDescription";

export const Maintenance = () => {
  // Content of the header
  const headerContent = {
    bgImg:
      "https://firebasestorage.googleapis.com/v0/b/kosha-439e6.appspot.com/o/backgroundImages%2Fmaintenance%20banner.jpeg?alt=media&token=cea5177c-8afa-4336-a8f1-a873492f2fd3",
    title: "Maintenanance & Repairs",
  };

  return (
    <div className="maintenance-page">
      {/* Header */}
      <PageHeader content={headerContent} />

      {/* Services */}
      <MaintenanceServices />

      {/* Services information */}
      <MaintenanceServicesDescription />
    </div>
  );
};
