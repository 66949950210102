import React from "react";
import { PageFooter } from "../components/footer/PageFooter";
import { HelpContent } from "../components/help/HelpContent";
import { PageHeader } from "../components/shared/PageHeader";

export const HelpPage = () => {
  // Content of the header
  const headerContent = {
    bgImg:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    title: "Help Center",
  };

  return (
    <>
      {/* Page header */}
      <PageHeader content={headerContent} />

      {/* <HelpBanner /> */}
      <HelpContent />

      {/* Page footer */}
      <PageFooter />
    </>
  );
};
