import {
  faAt,
  faPaperPlane,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CompanyLinks } from "../nav/CompanyLinks";
import { ServiceLinks } from "../nav/ServiceLinks";
import { Contact } from "./Contact";

export const PageFooter = () => {
  const phoneIcon = <FontAwesomeIcon icon={faPhoneAlt} />;
  const emailIcon = <FontAwesomeIcon icon={faAt} />;

  return (
    <>
      <div className="page-footer uk-section uk-section-secondary">
        <div className="uk-container">
          <div
            className="uk-grid uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-3@m"
            uk-grid="true"
          >
            {/* Links */}
            <div>
              <div className="uk-child-width-1-2" uk-grid="true">
                <div>
                  <h3 className="header uk-legend">Services & Products</h3>
                  <ul className="uk-list">
                    <ServiceLinks />
                  </ul>
                </div>
                <div>
                  <h3 className="header uk-legend">Company</h3>
                  <ul className="uk-list">
                    <CompanyLinks />
                  </ul>
                </div>
              </div>
            </div>

            {/* Contact us form */}
            <div>
              <Contact />
            </div>
            {/* Contact details*/}
            <div>
              <div>
                <h3 className="header uk-legend">Contact Detail</h3>
                <div className="contact-details uk-card uk-card-secondary uk-card-body uk-card-small">
                  <a className="uk-link" href="tel:+264852959595">
                    {phoneIcon} +264 (0) 85 2959 595
                  </a>

                  <a className="uk-link" href="mailto:info@koshaservices.com">
                    {emailIcon} info@koshaservices.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
