import { observer } from "mobx-react";
import React from "react";
import { CleaningCategory, Extra } from "../../interfaces/CleaningInterfaces";
import cleaningStore from "../../stores/CleaningStore";
import { CheckoutModal } from "../modal/CheckoutModal";

const store = cleaningStore;

// Regularity Date and Time
const RegularityDateTime = observer((props: any) => {
  const { store } = props;

  const date = new Date(store.regularityDateTime.date);
  const time = store.regularityDateTime.time;
  const regulary = store.regularityDateTime.isRegular ? "ON" : "OFF";
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);

  const disp = store.regularityDateTime && (
    <span>
      {`${month}, ${day}-${year}`} @ {time} {`Repeat: ${regulary}`}
    </span>
  );
  return <>{disp}</>;
});

// Categories
const Categories = observer((props: any) => {
  const { store } = props;

  const summary = store.categories.map((item: CleaningCategory) => (
    <span key={"category" + item.id}>
      {item.count} {item.name}
      {", "}
    </span>
  ));

  const disp = store.totalSelectedCategories ? (
    summary
  ) : (
    <span className="note"> Nothing selected </span>
  );

  return <>{disp}</>;
});

// Extras
const Extras = observer((props: any) => {
  const { store } = props;

  const summary = store.extras.map((item: Extra) => (
    <span key={"extra" + item.id}>
      {item.name}
      {", "}
    </span>
  ));

  const disp = store.totalSelectedExtras ? (
    summary
  ) : (
    <span className="note"> Nothing selected </span>
  );

  return <>{disp}</>;
});

// Pros
const Pro = observer((props: any) => {
  const { store } = props;

  const disp = store.pro.name && (
    <div className="selected-pro">
      <div>
        <div className="thumbnail">
          <img src={store.pro.profile_pic} alt="" />
        </div>
      </div>
      <div>
        <div className="body">
          <span>{store.pro.name} is happy take care of your home :)</span>
        </div>
      </div>
    </div>
  );
  return <>{disp}</>;
});

// Booking details

const logBookingDetails = (e: any) => {
  e.preventDefault();
  //   console.log("Booking details: ", store.getBookingDetails);
};

export const BookingSummary = (props: any) => {
  // const { store } = props;
  return (
    <>
      <div className="booking-summary">
        <div className="uk-card uk-card-default">
          <div className="uk-card-body">
            <h3 className="uk-card-title">Booking summary</h3>

            <p className="label">When</p>
            <p className="value">
              <RegularityDateTime store={store}></RegularityDateTime>
            </p>

            <p className="label">Rooms</p>
            <p className="value">
              <Categories store={store}></Categories>
            </p>

            <p className="label">Extras</p>
            <p className="value">
              <Extras store={store}></Extras>
            </p>

            {/* <hr />
            <div>
              <Pro store={store}></Pro>
            </div> */}
          </div>
          <div className="uk-card-footer uk-text-center uk-padding-remove">
            <button
              className="uk-button uk-button-primary uk-width-1-1 uk-height-1-1 uk-padding-small"
              onClick={logBookingDetails}
              uk-toggle="target: #checkout-modal"
            >
              Request Quote
            </button>
          </div>
        </div>
      </div>
      <CheckoutModal store={store} />
    </>
  );
};
