import React, { useEffect, useState } from "react";
import { db } from "../../../services/firebase";
import MobxHomeSpaceStore from "../../../stores/HomeSpaceStore";
import { Agent, Listing } from "../HomeSpace";

interface Props {
  listing: Listing;
}

const SearchPlaceCard = (props: Props) => {
  const [store] = useState(() => MobxHomeSpaceStore);
  const { listing } = props;
  const {
    agent: agentID,
    name,
    location,
    price,
    baths,
    bedrooms,
    area,
    thumbnails,
  } = listing;

  const [showMore, setShowMore] = useState(false);

  const getAgent = async () => {
    if (agentID) {
      const $doc = await db
        .collection(store.firebaseAgentsCollection)
        .doc(agentID);
      return $doc
        .get()
        .then((doc) => {
          if (doc.exists) {
            const $doc: Agent = {
              id: doc.id,
              name: "",
              cellphone: "",
              email: "",
              profile_pic: "",
              ...doc.data(),
            };
            store.setAgent($doc);
          } else {
            // doc.data() will be undefined in this case
            // console.log("No such document!");
          }
        })
        .catch((error) => {
          // console.log("Error getting document:", error);
        });
    } else {
      // console.log("Agent iD null", agentID);
    }
  };

  const viewPlace = () => {
    // store.showPropertyModal();
    getAgent();
    store.setListing(listing);
  };

  return (
    <div className="place-search-card uk-card uk-card-default uk-card-small">
      <div className="image uk-card-media-top">
        <img src={thumbnails[0]} alt="" />
      </div>
      <div className="body uk-card-body">
        <h3 className="title uk-card-title">{name}</h3>
        <p className="price">N${price}</p>
        <div className="options">
          {bedrooms && (
            <p className="beds">
              <span>{bedrooms}</span>
              <span className="label">Beds</span>
            </p>
          )}
          {baths && (
            <p className="baths">
              <span>{baths}</span>
              <span className="label">Baths</span>
            </p>
          )}
          {area && (
            <p className="area">
              <span>{area}</span>
              <span className="label">Sq. Ft.</span>
            </p>
          )}
        </div>

        <div className={"extra-info " + (showMore ? "show" : "")}>
          <p></p>
        </div>

        {/* {!showMore && (
          <button
            className="showMoreBtn uk-button uk-button-text"
            onClick={() => setShowMore(!showMore)}
          >
            Show More
            <span uk-icon="chevron-down"></span>
          </button>
        )}
        {showMore && (
          <button
            className="showMoreBtn uk-button uk-button-text"
            onClick={() => setShowMore(!showMore)}
          >
            Show Less
            <span uk-icon="chevron-up"></span>
          </button>
        )} */}
      </div>
      <div className="uk-card-footer">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a
            className="uk-button uk-button-text"
            href="#view-property"
            uk-toggle=""
            onClick={viewPlace}
          >
            View
          </a>

          {/* <a className="uk-button uk-button-text" href="/#">
            Agent
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default SearchPlaceCard;
