import React from "react";
import { BookingSubscriptions } from "./BookingSubscriptions";

export const Subscriptions = () => {
  return (
    <div className="subscriptions">
      {/* Subscriptions */}
      <BookingSubscriptions />
    </div>
  );
};
