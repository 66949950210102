/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// import useAutocomplete from "@material-ui/lab/useAutocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { db } from "../../services/firebase";
import TextField from "@material-ui/core/TextField";
import MobxSearchInputStore from "../../stores/SearchInputStore";
import MobxHomeSpaceSearchStore from "../../stores/HomeSpaceSearchStore";

export default function UseAutocomplete() {
  const [store] = useState(() => MobxHomeSpaceSearchStore);
  const [searchInpuStore] = useState(() => MobxSearchInputStore);
  const [value, setValue] = useState("");
  const placeholder = "Search City, Suburb, Agent";

  useEffect(() => {
    getSearchData();
    return () => {};
  }, []);

  const getSearchData = async () => {
    if (store.getAutocompleteLocations.length) return;

    const $db = await db.collection("location_metadata");
    return $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      store.setAutoCompleteLocations(docs);
    });
  };

  const onChange = (event: any, location: any) => {
    searchInpuStore.setLocation(location);
  };

  const onInputChange = (event: object, value: string, reason: string) => {
    setValue(value);
    if (!value) {
      searchInpuStore.setLocation("");
    }
  };

  // const {
  //   getRootProps,
  //   getInputLabelProps,
  //   getInputProps,
  //   getListboxProps,
  //   getOptionProps,
  //   groupedOptions,
  // } = useAutocomplete({
  //   id: "homespace-search",
  //   options: store.getListings,
  //   getOptionLabel: (option) => option.location,
  // });

  return (
    // <div>
    //   <div {...getRootProps()}>
    //     <form className="place-search uk-search uk-search-navbar">
    //       <span uk-search-icon=""></span>
    //       <input
    //         className=""
    //         {...getInputProps()}
    //         type="search"
    //         placeholder={placeholder}
    //       />
    //     </form>
    //   </div>
    //   {groupedOptions.length > 0 ? (
    //     <ul className="search-autocomplete-results" {...getListboxProps()}>
    //       {groupedOptions.map((option, index) => (
    //         <li {...getOptionProps({ option, index })}>{option.location}</li>
    //       ))}
    //     </ul>
    //   ) : null}
    // </div>
    <Autocomplete
      freeSolo
      className="place-search uk-search uk-search-navbar"
      id="place-search"
      disableClearable
      onChange={onChange}
      onInputChange={onInputChange}
      // value={value}
      options={store.getAutocompleteLocations.map((option) => option.location)}
      renderInput={(params: any) => (
        <TextField
          className="uk-search-input"
          {...params}
          label={placeholder}
          InputProps={{ ...params.InputProps, type: "search" }}
        />
      )}
    />
  );
}
