import React from "react";
import CleaningStore from "../../stores/CleaningStore";

export const BookingAppointmentDetails = () => {
  // Store
  const store = CleaningStore;

  const handleAddressChange = (e: any) => {
    const address: string = e.target.value;
    store.setAppointmentAddress(address);
  };
  const handleCityChange = (e: any) => {
    const city: string = e.target.value;
    store.setAppointmentAddress(city);
  };

  return (
    <div className="appointment-details uk-margin">
      {/* Appointment */}
      <h3 className="heading">
        Appointment <span>Address</span>
      </h3>
      <div className="uk-grid-small" uk-grid="">
        <div className="time uk-width-1-3@m">
          <label className="uk-form-label" htmlFor="appointment-address">
            Appointment Address
          </label>
          <input
            className="uk-input"
            type="text"
            name="appointment-address"
            placeholder="House address"
            onChange={handleAddressChange}
            required
          />
        </div>
        <div className="time uk-width-1-3@m">
          <label className="uk-form-label" htmlFor="appointment-city">
            Appointment City
          </label>
          <input
            className="uk-input"
            type="text"
            placeholder="Town/City"
            name="appointment-city"
            onChange={handleCityChange}
            required
          />
        </div>
      </div>
    </div>
  );
};
