import React from "react";

const ViewPlaceCard = (props: any) => {
  const { src, caption } = props;
  return (
    <a href={src} data-caption={caption}>
      <img
        data-src={src}
        alt=""
        uk-cover=""
        uk-img="target: !.uk-slideshow-items"
      />
    </a>
  );
};

export default ViewPlaceCard;
