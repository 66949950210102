import React from "react";
import cleaningSubscriptionsStore from "../../stores/CleaningSubscriptionStore";

export const SubscriptionCard = (props: any) => {
  const pack = props.item;
  const { title, subtitle, description, price } = pack;

  const dollars = Math.trunc(price);
  const cents = Math.trunc((price % dollars) * 100);

  const displayDescription = description.length ? (
    description.map((desc: any, key: any) => (
      <li className="description" key={key}>
        {desc}
      </li>
    ))
  ) : (
    <p></p>
  );

  const setModalName = () => {
    const store = cleaningSubscriptionsStore;
    store.setModalName(title);
  };
  return (
    <div className="subscription-card uk-card uk-card-default uk-card-small uk-card-hover uk-text-center">
      <div className="content uk-card-body">
        <h5 className="title">{title}</h5>
        <hr />
        <p className="price">
          <span>N$</span> {dollars} <span>{cents === 0 ? "00" : cents}</span>
        </p>
        <h6 className="subtitle">{subtitle}</h6>
        <div className="descrption-list uk-list uk-list-circle uk-text-left">
          {displayDescription}
        </div>
      </div>
      <button
        className="uk-button uk-button-secondary"
        uk-toggle="target: #cleanig-subscription-modal"
        onClick={setModalName}
      >
        Choose Plan
      </button>
    </div>
  );
};
