import React from "react";

export const LeaderCard = (props: any) => {
  const member = props.item;
  const { profile_pic, name, role, position } = member;

  const thumbnail = profile_pic
    ? profile_pic
    : process.env.PUBLIC_URL + "/images/person_placeholder.png";

  return (
    <div className="team-card uk-card uk-card-default uk-card-small uk-card-hover uk-text-center">
      <div className="uk-card-media-top">
        <img className="cover" src={thumbnail} alt="" />
      </div>
      <div className="uk-card-footer">
        <h5 className="name">{name}</h5>
        <p className="position">{position}</p>
        <p className="role">{role}</p>
      </div>
    </div>
  );
};
