import { observer } from "mobx-react";
import React, { useState } from "react";
import MobxHomeSpaceStore from "../../stores/HomeSpaceStore";
import ContactAgentForm from "./view_property/ContactAgentForm";

const ContactAgentModal = observer(() => {
  const [store] = useState(() => MobxHomeSpaceStore);

  return (
    <div id="modal-contact-agent" uk-modal="">
      <div className="uk-modal-dialog uk-margin-auto-vertical">
        <div className="uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close=""
          ></button>
          {store.getAgent && <ContactAgentForm agent={store.getAgent} />}
        </div>
      </div>
    </div>
  );
});

export default ContactAgentModal;
