import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { db } from "../../services/firebase";
import { LeaderCard } from "./LeaderCard";

export const Leadership = () => {
  // Firestore collection
  const collectionName = "ourLeadership";
  // Store data
  const [leaders, setLeaders] = useState([]);
  // Font awesome icons, import
  const leftIcon = <FontAwesomeIcon icon={faChevronLeft} />;
  const rightIcon = <FontAwesomeIcon icon={faChevronRight} />;

  useEffect(() => {
    getLeaders();
    return () => {};
  }, []);

  const getLeaders = async () => {
    const $db = await db.collection(collectionName).orderBy("rank");
    return $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      setLeaders(docs);
    });
  };

  const displayOurLeaders = leaders.map((member, key) => (
    <li className="team-member" key={key}>
      <LeaderCard item={member} />
    </li>
  ));

  return (
    <div className="our-team">
      {/* Our Leaders */}
      <h3 className="heading">
        Our <span>Leaders</span>
      </h3>
      <div className="uk-slider-container-offset" uk-slider="true">
        <div
          className="uk-position-relative uk-visible-toggle uk-light"
          tabIndex={-1}
        >
          <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-small uk-grid-match uk-margin-large-bottom">
            {displayOurLeaders}
          </ul>
        </div>
        <a
          className="slider-controls previous"
          href="/#"
          uk-slider-item="previous"
        >
          {leftIcon}
        </a>
        <a className="slider-controls next" href="/#" uk-slider-item="next">
          {rightIcon}
        </a>
      </div>
    </div>
  );
};
