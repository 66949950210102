import React from "react";
import { Link } from "react-router-dom";

export const ServiceIntro = (props: any) => {
  const service = props.service;
  const title = service.title;
  const description = service.description;
  const bookingRouteTo = service.bookingRouteTo
    ? service.bookingRouteTo
    : "booking";
  const packagesRouteTo = service.packagesRouteTo;
  const bookingBtn = service.bookingBtn;
  const packagesBtn = service.packagesBtn;

  return (
    <div className="service-intro">
      <div className="uk-card uk-card-default uk-card-body uk-width-1-2@m">
        <h3 className="uk-card-title">{title}</h3>
        <p>{description}</p>
        <div className="buttons">
          <Link to={"/" + bookingRouteTo}>
            <button className="uk-button uk-button-primary">
              {bookingBtn}
            </button>
          </Link>

          <Link to={"/" + packagesRouteTo}>
            <button className="uk-button uk-button-lighter-primary">
              {packagesBtn}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
