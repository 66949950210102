import firebase from "firebase/app";
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyC1aIW25AtbFAiCwoHc98GmDupMANzg2kc",
	authDomain: "kosha-439e6.firebaseapp.com",
	projectId: "kosha-439e6",
	storageBucket: "kosha-439e6.appspot.com",
	messagingSenderId: "830375952316",
	appId: "1:830375952316:web:c84bb811033cb1b6a575b9",
	measurementId: "G-L9BR02E2DQ",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const auth = firebase.auth;
export const db = firebase.firestore();
export const storage = firebase.storage();
