import React from "react";
import laundryStore from "../../stores/LaundryStore";
import { LaundryModal } from "../modal/LaundryModal";
import { CarpetCleaningBody } from "./CarpetCleaningBody";

export const CarpetCleaning = ({ bg }: any) => {
  const store = laundryStore;

  return (
    <div
      className="carpet-cleaning uk-background-cover"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="uk-section">
        <div className="carpet-cleaning-container uk-container">
          <CarpetCleaningBody />
        </div>
      </div>
      <LaundryModal store={store} />;
    </div>
  );
};
