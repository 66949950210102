import React from "react";

const PlaceCardMini = (props: any) => {
  const { src } = props;
  return (
    <div className="place-card-mini uk-inline">
      <img className="media" src={src} alt="" />
      <div className="body uk-overlay uk-overlay-primary uk-position-center">
        <h3 className="address uk-card-title">Merensky Towers, Auspannplatz</h3>
        <h3 className="price">$60,000</h3>
        <div className="options">
          <p className="beds">
            <span>3</span>
            <span className="label">Beds</span>
          </p>
          <p className="baths">
            <span>2</span>
            <span className="label">Baths</span>
          </p>
          <p className="area">
            <span>1,050</span>
            <span className="label">Sq. Ft.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlaceCardMini;
