import React, { useState } from "react";
import { ProductReviewsList } from "./ProductReviewsList";
import { ProductWriteReview } from "./ProductWriteReview";

export const ProductReviews = () => {
	const [isWrite, setWriteReview] = useState(false);
	const setActiveTabIndex = (index: number) => {
		switch (index) {
			case 1:
				console.log("Is Write => ", isWrite);
				setWriteReview(false);
				break;
			case 2:
				console.log("Is Write => ", isWrite);
				setWriteReview(true);
				break;
			default:
				setWriteReview(false);
		}
	};

	const activeReviewTab = isWrite ? (
		<ProductWriteReview />
	) : (
		<ProductReviewsList />
	);
	return (
		<div className="reviews uk-margin">
			<ul className="reviews-nav" uk-tab="true">
				<li className="uk-active">
					<a href="/#" onClick={() => setActiveTabIndex(1)}>
						Reviews (2)
					</a>
				</li>
				<li>
					<a href="/#" onClick={() => setActiveTabIndex(2)}>
						Write a review
					</a>
				</li>
			</ul>
			<div className="reviews-content">{activeReviewTab}</div>
		</div>
	);
};
