import React from "react";

export const ProductWriteReview = () => {
	return (
		<div className="reviews-write">
			<form className="uk-grid-small" uk-grid="true">
				<div className="uk-width-1-2@s">
					<label htmlFor="name" className="uk-form-label">
						Full name
					</label>
					<input
						className="uk-input"
						name="name"
						type="text"
						placeholder="Name"
					/>
				</div>
				<div className="uk-width-1-2@s">
					<label htmlFor="email" className="uk-form-label">
						E-mail
					</label>
					<input
						className="uk-input"
						name="email"
						type="email"
						placeholder="Email"
					/>
				</div>
				<div className="uk-width-1-1">
					<label htmlFor="review" className="uk-form-label">
						Your review
					</label>
					<textarea
						className="uk-textarea"
						rows={5}
						name="review"
						placeholder="Write your review..."
					></textarea>
				</div>
				<div className="">
					<button className="uk-button uk-button-primary">Post review</button>
				</div>
			</form>
		</div>
	);
};
