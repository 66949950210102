import { observer } from "mobx-react";
import React, { useState } from "react";
import { BookingCustomerDetails } from "../../interfaces/BookingInterfaces";
import { createRecords } from "../../services/airtableFunctions";
import swal from "sweetalert";
import { ValidateEmail } from "../../services/ValidateEmail";
declare var UIkit: any;

export const CheckoutModal = observer((props: any) => {
  const { store } = props;

  const bookingDetails = store.getBookingDetailsSummary;
  const initCustomerDetails: BookingCustomerDetails = {
    firstname: "",
    lastname: "",
    email: "",
    cellphone: "",
    comments: "",
    service: "",
  };
  const [customerDetails, setCustomerDetails] = useState(initCustomerDetails);
  const [dispError, setDispErrors] = useState([<></>]);

  // Airtable constants
  const table = "Cleaning";

  const onQuoteRequestSent = (e: any) => {
    e.preventDefault();

    // Validate form
    const errorsArray = validate();
    if (!validate().length) {
      const customerOrder = {
        ...customerDetails,
        ...bookingDetails,
      };

      // Hide modal
      const modal = UIkit.modal("#checkout-modal");
      if (modal) {
        modal.hide();
      }

      createRecords(table, customerOrder)
        .then((records: any) => {
          if (records.length) {
            records.forEach((record: any) => {
              swal(
                "Request Sent!",
                `Your Request number is #cleaning:${record.getId()}`,
                "success"
              );
            });
          } else {
            console.log("Not array");
            swal(
              "Request Sent!",
              `Your Request number is #cleaning:${records.getId()}`,
              "success"
            );
          }
          // Reset booking form
          setCustomerDetails(initCustomerDetails);
        })
        .catch((err: any) => {
          // console.error(err);
          swal("Error sending Request!", "Please contact admin", "error");
        });
    } else {
      const errorsDisp = errorsArray.map((errorMsg: string, key: number) => {
        return (
          <p key={key} style={{ color: "red" }}>
            {errorMsg}
          </p>
        );
      });
      setDispErrors(errorsDisp);
    }
  };

  const validate = () => {
    const validationErrors = [];
    // Validate name
    if (!customerDetails.firstname.length || !customerDetails.lastname.length)
      validationErrors.push("Please fill in your name");

    // Validate email
    if (!ValidateEmail(customerDetails.email))
      validationErrors.push("Invalid email");

    // validate cellphone
    if (!customerDetails.cellphone.length)
      validationErrors.push("Invalid email");

    return validationErrors;
  };

  return (
    <div id="checkout-modal" className="modal uk-flex-top" uk-modal="true">
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button
          className="uk-modal-close-default"
          type="button"
          uk-close="true"
        ></button>

        <div className="modal-header">
          <h2 className="title uk-modal-title">
            Personal <span>Details</span>
          </h2>
        </div>
        <div className="modal-content">
          <form
            className="modal-form uk-grid-small"
            uk-grid="true"
            onSubmit={(e) => onQuoteRequestSent(e)}
          >
            <div className="uk-width-1-2@s">
              <div>
                <label className="uk-form-label" htmlFor="booking-firstname">
                  First Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-firstname"
                    type="text"
                    value={customerDetails.firstname}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        firstname: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div>
                <label className="uk-form-label" htmlFor="booking-lastname">
                  Last Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-lastname"
                    type="text"
                    value={customerDetails.lastname}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        lastname: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@s">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="booking-email">
                  Email
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-email"
                    type="email"
                    value={customerDetails.email}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@s">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="booking-cellphone">
                  Cellphone
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-cellphone"
                    type="text"
                    value={customerDetails.cellphone}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        cellphone: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@s">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="booking-comments">
                  Notes (Special Requests)
                </label>
                <textarea
                  id="booking-comments"
                  className="uk-textarea"
                  rows={5}
                  value={customerDetails.comments}
                  onChange={(e: any) =>
                    setCustomerDetails({
                      ...customerDetails,
                      comments: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
            <div className="uk-width-1-1">{dispError}</div>
            <div className="uk-width-1-1">
              <div className="uk-margin uk-text-center">
                <button
                  className="submit uk-button uk-button-primary"
                  type="submit"
                  onClick={(e) => onQuoteRequestSent(e)}
                >
                  Get Quote
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});
