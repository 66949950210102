import React from "react";
import { Banner } from "../components/nav/Banner";
import { ClientsReview } from "../components/home/ClientsReview";
import { SectionCompanyCommitment } from "../components/home/SectionCompanyCommitment";
import { SectionCorporateClients } from "../components/home/SectionCorporateClients";
import { PageFooter } from "../components/footer/PageFooter";
import { SocialLinks } from "../components/footer/SocialLinks";

export const HomePage = () => {
  return (
    <>
      <Banner />
      <SectionCorporateClients />
      <ClientsReview />
      <SectionCompanyCommitment />
      <PageFooter />
      <SocialLinks />
    </>
  );
};
