import React from "react";

const NearByPlaceCard = () => {
  return (
    <div className="near-by uk-card-hover">
      <div className="image">
        <img
          src="https://images.pexels.com/photos/2119713/pexels-photo-2119713.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
          alt=""
        />
      </div>
      <div className="body">
        <h6 className="title">Oshakati</h6>
        <p className="description">15 Places</p>
      </div>
    </div>
  );
};

export default NearByPlaceCard;
