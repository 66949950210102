import React from "react";
import laundryStore from "../../stores/LaundryStore";
import { PriceCleaningCard } from "./PriceCleaningCard";

export const MattressCleaningBody = () => {
  const setModalName = () => {
    const store = laundryStore;
    store.setModalName("Mattress Care");
  };
  const packages = [
    {
      size: "Single Bed Mattress",
      price: 300,
    },
    {
      size: "Double Bed Mattress",
      price: 350,
    },
    {
      size: "Queen Bed Mattress",
      price: 450,
    },
    {
      size: "Qeen Bed Mattress",
      price: 550,
    },
  ];

  const dispMatList = packages.map((item, index) => (
    <PriceCleaningCard key={index} size={item.size} price={item.price} />
  ));

  return (
    <div className="uk-grid uk-grid-large uk-child-width-1-2@s" uk-grid={""}>
      <div className="mattress-intro">
        {/* Display sectoin header */}
        <h1 className="laundry-header heading">
          <span>Mattress</span> Care
        </h1>

        <h3 className="service-description">
          Wet and Dry <br /> On site cleaning
        </h3>

        <div>
          <button
            className="bookBtn uk-button uk-button-primary"
            uk-toggle="target: #laundry-modal"
            onClick={setModalName}
          >
            Book Now
          </button>
        </div>
      </div>

      {/* <LaundryModal name={"Mattress Care"} /> */}

      <div>
        {/* Display laundry Prices */}
        <div className="cleaning-packages">{dispMatList}</div>
      </div>
    </div>
  );
};
