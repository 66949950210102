import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { db } from "../../../services/firebase";
import MobxHomeSpaceSearchStore from "../../../stores/HomeSpaceSearchStore";
import MobxSearchInputStore from "../../../stores/SearchInputStore";
import { Listing } from "../HomeSpace";
import SearchPlaceCard from "./SearchPlaceCard";

const SearchResultsContent = observer(() => {
  const [searchInputStore] = useState(() => MobxSearchInputStore);
  const [store] = useState(() => MobxHomeSpaceSearchStore);

  const [results, setResults] = useState<Listing[]>();

  useEffect(() => {
    if (searchInputStore.getLocation) getListings();
    return () => {};
  }, [searchInputStore.getLocation]);

  const getListings = async () => {
    // if (store.getListings.length) return;
    console.log("Get listings....");

    const $db = await db
      .collection(store.firebaseListingsCollection)
      .where("location", "array-contains", searchInputStore.getLocation);
    return $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      // store.setListings(docs);
      setResults(docs);
    });
  };

  const dispListings = results?.map((listing) => (
    <div key={listing.id}>
      <SearchPlaceCard listing={listing} />
    </div>
  ));

  return (
    <div>
      {/* Results */}
      {/* <h4>
        Results: <strong>10</strong>
      </h4> */}

      <div
        className="uk-grid uk-grid-medium uk-child-width-1-1@s uk-child-width-1-3@m uk-child-width-1-4@l"
        uk-grid="true"
      >
        {dispListings}
      </div>
    </div>
  );
});

export default SearchResultsContent;
