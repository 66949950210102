import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CleaningCategory } from "../../interfaces/CleaningInterfaces";
import { CategoryCard } from "./CategoryCard";

export const CleaningCategories = (props: any) => {
	// Catergories
	const categories = props.categories;
	// Font awesome icons, import
	const leftIcon = <FontAwesomeIcon icon={faChevronLeft} />;
	const rightIcon = <FontAwesomeIcon icon={faChevronRight} />;

	const displayBookingCategories = categories.map(
		(category: CleaningCategory) => (
			<li className="category-item" key={category.id}>
				<CategoryCard item={category} />
			</li>
		)
	);

	return (
		<>
			<div className="booking-categories uk-margin uk-margin-large-top">
				<h3 className="heading">
					Choose <span>Category</span>
				</h3>

				<div className="uk-slider-container-offset" uk-slider="finite: true">
					<div
						className="uk-position-relative uk-visible-toggle uk-light"
						tabIndex={-1}
					>
						<ul className="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l uk-grid-small">
							{displayBookingCategories}
						</ul>
					</div>
					<a
						className="slider-controls previous"
						href="/#"
						uk-slider-item="previous"
					>
						{leftIcon}
					</a>
					<a className="slider-controls next" href="/#" uk-slider-item="next">
						{rightIcon}
					</a>
				</div>
			</div>
		</>
	);
};
