import { makeAutoObservable, autorun, toJS } from "mobx";
import {
  AppointmentDetails,
  CleaningCategory,
  Extra,
  Pro,
  RegularityDateTime,
} from "../interfaces/CleaningInterfaces";
import MobxCategoryItem from "./MobxCategoryItem";
import MobxExtraItem from "./MobxExtraItem";

class CleaningStore {
  categories: CleaningCategory[] = []; // Categories observable
  extras: CleaningCategory[] = []; // Extras observable
  pro: Pro = {
    // Pro's Observable
    id: 0,
    name: "",
  };

  regularityDateTime: RegularityDateTime = {
    isRegular: true,
    date: null,
  };

  appointmentDetails: AppointmentDetails = {
    id: 0,
    city: "",
    address: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  // Categories
  // Add categories
  addCategories = (category: CleaningCategory) => {
    this.categories.push(new MobxCategoryItem(category));
  };
  // Remove categories
  removeCategory = (category: CleaningCategory) => {
    const filteredCategories = this.categories.filter(
      (item: CleaningCategory) => item.id !== category.id
    );
    this.categories = filteredCategories;
  };
  get totalSelectedCategories() {
    return this.categories.length;
  }

  // Specify Count
  // UpdateCategory
  updateCategoryCount = (
    _categoryCountItem: MobxCategoryItem,
    index: number
  ) => {
    this.categories[index] = _categoryCountItem;
  };

  // Extras
  // Add Extras
  addExtras = (_extra: Extra) => {
    this.extras.push(new MobxExtraItem(_extra));
  };
  // Remove extra
  removeExtra = (_extra: Extra) => {
    const filteredExtras = this.extras.filter(
      (item: Extra) => item.id !== _extra.id
    );
    this.extras = filteredExtras;
  };
  get totalSelectedExtras() {
    return this.extras.length;
  }

  // Pros
  selectAPro = (_pro: Pro) => {
    this.pro = _pro;
  };
  resetPro = () => {
    this.pro = { id: 0, name: "" };
  };
  get getProsRating() {
    return this.pro.rating;
  }

  // Regularity
  setIsRegular = (isRegular: Boolean) => {
    this.regularityDateTime.isRegular = isRegular;
  };
  setRegularityDate = (date: number) => {
    this.regularityDateTime.date = new Date(date);
  };
  // setRegularityTime = (time: number) => {
  //   if (this.regularityDateTime.date !== null) {
  //     this.regularityDateTime.date!.setTime(time);
  //   } else {
  //     this.regularityDateTime.date = new Date(time);
  //   }
  // };

  // Return booking details
  get getBookingDetails() {
    const bookingDetails = {
      categories: toJS(this.categories),
      extras: toJS(this.extras),
      pro: toJS(this.pro),
      date: toJS(this.regularityDateTime),
    };

    return bookingDetails;
  }

  get getBookingDetailsSummary() {
    const _categories = this.categories.map(
      (item, key) => `${item.name}: ${item.count}`
    );
    const _extras = this.extras.map((item, key) => `${item.name}`);
    const bookingDetails = {
      categories: _categories.join(";\n"),
      extras: _extras.join(";\n"),
      pro: this.pro.name,
      date: this.regularityDateTime.date
        ? this.regularityDateTime.date!.valueOf()
        : Date.now(),
      regularity: this.regularityDateTime.isRegular ? "Once" : "regular",
    };

    return bookingDetails;
  }

  // Appointment
  setAppointmentAddress = (address: string) => {
    this.appointmentDetails.address = address;
  };
  setAppointmentCity = (city: string) => {
    this.appointmentDetails.city = city;
  };
  get getAppointmentDetails() {
    return this.appointmentDetails;
  }
}

const cleaningStore = new CleaningStore();

autorun(() => {});

export default cleaningStore;
