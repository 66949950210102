import React from "react";
import { Founders } from "./Founders";
import { Leadership } from "./Leadership";
// import { OurPros } from "./OurPros";

export const Team = () => {
  return (
    <div className="about-team uk-section uk-section-muted">
      <div className="uk-container">
        {/* Our Founders */}
        <Founders />

        {/* Our Leaders */}
        <Leadership />

        {/* Our Pros */}
        {/* <OurPros /> */}
      </div>
    </div>
  );
};
