import React from "react";
import PlaceCardMini from "../PlaceCardMini";

const ListingsSlider = () => {
  const soldListings = [
    "https://images.pexels.com/photos/276724/pexels-photo-276724.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    "https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?cs=srgb&dl=pexels-julia-kuzenkov-1974596.jpg&fm=jpg",
    "https://images.pexels.com/photos/3586777/pexels-photo-3586777.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/4543115/pexels-photo-4543115.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    "https://images.pexels.com/photos/2119713/pexels-photo-2119713.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    "https://images.pexels.com/photos/4738075/pexels-photo-4738075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/3825883/pexels-photo-3825883.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  ];
  return (
    <div>
      <div className="uk-slider-container-offset" uk-slider="">
        <div
          className="uk-position-relative uk-visible-toggle uk-light"
          tabIndex={-1}
        >
          <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid">
            {soldListings.map((listing, index) => (
              <li key={index}>
                <PlaceCardMini src={listing} />
              </li>
            ))}
          </ul>

          <a
            className="uk-position-center-left uk-position-small uk-hidden-hover"
            href="/#"
            uk-slider-item="previous"
          >
            <span uk-icon="icon: chevron-left"></span>
          </a>
          <a
            className="uk-position-center-right uk-position-small uk-hidden-hover"
            href="/#"
            uk-slider-item="next"
          >
            <span uk-icon="icon: chevron-right"></span>
          </a>
        </div>

        <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
      </div>
    </div>
  );
};

export default ListingsSlider;
