import { makeAutoObservable, autorun } from "mobx";
import { Agent, Listing } from "../components/homespace/HomeSpace";

class HomeSpaceStore {
  private propertyModal = true;
  // Firestore collection
  firebaseAgentsCollection = "agents";
  // Firestore collection
  firebaseListingsCollection = "listings";
  // Agents +++++++++++++++++++++++++++
  private agents: Agent[] = [];

  // All Listings +++++++++++++++++++++
  private listings: Listing[] = [];

  // Selected agent +++++++++++++++++++
  private agent: Agent = {
    id: "",
    cellphone: "",
    email: "",
    profile_pic: "",
    name: "",
  };

  // Selected listing +++++++++++++++++
  private listing: Listing = {
    id: "",
    agent: "",
    baths: 0,
    bedrooms: 0,
    area: 0,
    name: "",
    location: "",
    description: [],
    price: "",
    thumbnails: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  // Get Agents +++++++++++++++++++
  setAgents = (agents: Agent[]) => {
    this.agents.push(...agents);
  };
  get getAgents() {
    return this.agents;
  }

  // Get listings +++++++++++++++++++
  setListings = (listings: Listing[]) => {
    this.listings.push(...listings);
  };
  get getListings() {
    return this.listings;
  }

  // Selected Agent +++++++++++++++++++
  setAgent = (agent: Agent) => {
    this.agent = agent;
  };
  get getAgent() {
    return this.agent;
  }

  // Selected Listing +++++++++++++++++++
  setListing = (listing: Listing) => {
    this.listing = listing;
  };
  get getListing() {
    return this.listing;
  }

  // Property modal
  showPropertyModal = () => {
    this.propertyModal = true;
  };
  hidePropertyModal = () => {
    this.propertyModal = false;
  };
  get isPropertyModalVisible() {
    return this.propertyModal;
  }
}

const MobxHomeSpaceStore = new HomeSpaceStore();

autorun(() => {});

export default MobxHomeSpaceStore;
