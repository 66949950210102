import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.scss";
import { TopNav } from "./components/nav/TopNav";
import { AboutPage } from "./pages/AboutPage";
import { BlogPage } from "./pages/BlogPage";
import { CleaningPage } from "./pages/CleaningPage";
import { HelpPage } from "./pages/HelpPage";
import { HomePage } from "./pages/HomePage";
import { HomeSpacePage } from "./pages/HomeSpacePage";
import { InternetPage } from "./pages/InternetPage";
import { LaundryPage } from "./pages/LaundryPage";
import { LoginPage } from "./pages/LoginPage";
import { MaintenancePage } from "./pages/MaintenancePage";
import { ShopPage } from "./pages/ShopPage";
import { SignupPage } from "./pages/SignupPage";
import { SingleProductPage } from "./pages/SingleProductPage";
import { Cart } from "./components/shop/Cart";
import AgentsListingsPage from "./components/homespace/agent/AgentsListingsPage";
// import { NewsletterModal } from "./components/modal/NewsletterModal";
declare var UIkit: any;

function App() {
  useEffect(() => {
    if (typeof Storage !== "undefined") {
      // Retrive newsletter subscription
      // const isSubscribed = localStorage.newsletter;
      // If not subscribed, display newsletter modal
      // if (isSubscribed !== "subscribed")
      // UIkit.modal("#newsletter-modal").show();
    } else {
      // document.getElementById("result").innerHTML = "Sorry, your browser does not support Web Storage...";
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <TopNav />

        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/shop">
            <ShopPage />
          </Route>
          <Route exact path="/product">
            <SingleProductPage />
          </Route>
          <Route exact path="/about">
            <AboutPage />
          </Route>
          <Route exact path="/cart">
            <Cart />
          </Route>
          {/* Homespace */}
          <Route exact path="/homespace">
            <HomeSpacePage />
          </Route>
          {/* Agent */}
          <Route exact path="/agents-listings/:agentID">
            <AgentsListingsPage />
          </Route>

          {/* Maintenance */}
          <Route exact path="/maintenance">
            <MaintenancePage />
          </Route>

          {/* Booking */}
          <Route exact path="/cleaning">
            <CleaningPage />
          </Route>
          {/* Laundry */}
          <Route exact path="/laundry">
            <LaundryPage />
          </Route>
          {/* Internet */}
          <Route exact path="/internet">
            <InternetPage />
          </Route>
          {/* Blogging */}
          <Route exact path="/blogs">
            <BlogPage />
          </Route>
          {/* Login */}
          <Route exact path="/login">
            <LoginPage />
          </Route>
          {/* Sign-up */}
          <Route exact path="/sign-up">
            <SignupPage />
          </Route>
          {/* FAQ */}
          <Route exact path="/faq">
            <HelpPage />
          </Route>
          <Route exact path="/help">
            <HelpPage />
          </Route>
          {/* Wildcard route */}
          <Route exact path="/*">
            <Redirect to="/help" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
