import React from "react";

export const PriceCleaningCard = (props: any) => {
	const { size, price } = props;
	return (
		<div className="price-cleaning-card uk-card">
			<div className="content">
				<p>{size}</p>
			</div>

			<div className="action">
				<a href="/#">
					N${price},00
				</a>
			</div>
		</div>
	);
};
