import React from "react";
import { PageHeader } from "../components/shared/PageHeader";
import { CarpetCleaning } from "../components/laundry/CarpetCleaning";
import { LaundryCleaning } from "../components/laundry/LaundryCleaning";
import { MattressCleaning } from "../components/laundry/MattressCleaning";
import { SofaCleaning } from "../components/laundry/SofaCleaning";
import { PageFooter } from "../components/footer/PageFooter";

export const LaundryPage = () => {
  const headerContent = {
    bgImg:
      "https://images.pexels.com/photos/271711/pexels-photo-271711.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    title: "Laundry Services",
  };

  return (
    <>
      <PageHeader content={headerContent} />
      <LaundryCleaning
        bg={
          "https://images.pexels.com/photos/325876/pexels-photo-325876.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
        }
      />

      <MattressCleaning
        bg={
          "https://images.pexels.com/photos/5998134/pexels-photo-5998134.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
        }
      />

      <SofaCleaning
        bg={
          "https://images.pexels.com/photos/276566/pexels-photo-276566.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
        }
      />

      <CarpetCleaning
        bg={
          "https://images.pexels.com/photos/38325/vacuum-cleaner-carpet-cleaner-housework-housekeeping-38325.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
        }
      />

      {/* Page footer */}
      <PageFooter />
    </>
  );
};
