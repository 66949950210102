import React from "react";
import { Cleaning } from "../components/cleaning/Cleaning";
import { PageFooter } from "../components/footer/PageFooter";

export const CleaningPage = () => {
  return (
    <>
      <div className="cleaning uk-section uk-section-default">
        <div className="uk-container">
          <Cleaning />
        </div>
      </div>

      {/* Page footer */}
      <PageFooter />
    </>
  );
};
