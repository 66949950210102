import {
  faHandsWash,
  faWrench,
  faStore,
  faGlobe,
  faBuilding,
  faTshirt,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  koshaTabContent,
  realEstateTabContent,
  maintenanceTabContent,
  laundryTabContent,
  internetTabContent,
} from "./MidNavTabContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef, useEffect, useRef } from "react";
import { useState } from "react";
import { ServiceIntro } from "../home/ServiceIntro";
import { ShopIntro } from "../home/ShopIntro";

export const MidNav = () => {
  // Stores Content of the active tab.
  const [activeTabData, setActiveTabData] = useState({});
  // True, if Shop Tab is Active; False when other tabs are active.
  const [isShopTabActive, setShopTab] = useState(false);

  // Tab Icons
  const koshaIcon = <FontAwesomeIcon icon={faHandsWash} />;
  const realEstateIcon = <FontAwesomeIcon icon={faBuilding} />;
  const maintenanceIcon = <FontAwesomeIcon icon={faWrench} />;
  const laundryIcon = <FontAwesomeIcon icon={faTshirt} />;
  const internetIcon = <FontAwesomeIcon icon={faGlobe} />;
  const shopIcon = <FontAwesomeIcon icon={faStore} />;

  const leftIcon = <FontAwesomeIcon icon={faChevronLeft} />;
  const rightIcon = <FontAwesomeIcon icon={faChevronRight} />;

  useEffect(() => {
    // On compponent mount, the Kosha Tab will display.
    setActiveTabData(koshaTabContent);
  }, []);

  const setActiveTabIndex = (index: number) => {
    // The shop tab is active
    if (index === -1) {
      setShopTab(true);
      setActiveTabData({});
      return;
    }

    // The shop tab is not active
    setShopTab(false);
    switch (index) {
      case 1:
        setActiveTabData(koshaTabContent);
        break;
      case 2:
        setActiveTabData(laundryTabContent);
        break;
      case 3:
        setActiveTabData(realEstateTabContent);
        break;
      case 4:
        setActiveTabData(maintenanceTabContent);
        break;
      case 5:
        setActiveTabData(internetTabContent);
        break;
      default:
        setActiveTabData(koshaTabContent);
    }
  };

  // If shop tab is active - display the shopIntro component
  // If shop tab is inactive/false -  display the ServiceIntro component.
  const intro = isShopTabActive ? (
    <ShopIntro />
  ) : (
    // Pass the active component data.
    <ServiceIntro service={activeTabData} />
  );

  const ulRef = useRef<HTMLUListElement>(null);

  const [leftScrollBtnVisibility, setLeftScrollBtnVisibility] = useState(false);
  const [rightScrollBtnVisibility, setRightScrollBtnVisibility] = useState(
    false
  );

  useEffect(() => {
    scrollCriteria();
  }, []);

  // Check scroll criteria

  const scrollCriteria = () => {
    const ulElement = ulRef.current;
    console.log("Ran");

    // Right scroll Visibility
    if (ulElement) {
      const maxScrollLeft = ulElement.scrollWidth - ulElement.clientWidth;
      const scrollLeft = ulElement.scrollLeft;
      // Difference is equals to maxScroll
      const difference = maxScrollLeft - scrollLeft;

      if (difference === maxScrollLeft) setRightScrollBtnVisibility(false);
      else setRightScrollBtnVisibility(true);
    }
    // Left scroll visibilty
    if (ulElement) {
      const maxScrollLeft = ulElement.scrollWidth - ulElement.clientWidth;
      const scrollLeft = ulElement.scrollLeft;
      // Difference is equals to 0
      const difference = maxScrollLeft - scrollLeft;
      if (difference === 0) {
        setLeftScrollBtnVisibility(false);
      } else {
        setLeftScrollBtnVisibility(true);
      }
    }
  };

  const onScrollRight = () => {
    const ulElement = ulRef.current;
    if (ulElement) {
      const maxScrollLeft = ulElement.scrollWidth - ulElement.clientWidth;
      ulElement.scrollBy({
        top: 0, // could be negative value
        left: -maxScrollLeft,
        behavior: "smooth",
      });
    }
    scrollCriteria();
  };

  const onScrollLeft = () => {
    const ulElement = ulRef.current;
    if (ulElement) {
      const maxScrollLeft = ulElement.scrollWidth - ulElement.clientWidth;
      ulElement.scrollBy({
        top: 0, // could be negative value
        left: maxScrollLeft,
        behavior: "smooth",
      });
    }
    scrollCriteria();
  };

  return (
    <div>
      {rightScrollBtnVisibility && (
        <button className="sliderBtn left" onClick={onScrollRight}>
          {leftIcon}
        </button>
      )}
      <div className="mid-nav">
        <ul className="uk-child-width-expand" ref={ulRef} uk-tab="true">
          <li className="mid-service-item uk-active">
            <a href="/#" onClick={() => setActiveTabIndex(1)}>
              <span className="service-icon">{koshaIcon}</span>
              Kosha
            </a>
          </li>
          <li className="mid-service-item">
            <a href="/#" onClick={() => setActiveTabIndex(3)}>
              <span className="service-icon">{realEstateIcon}</span>
              Home Search
            </a>
          </li>
          <li className="mid-service-item">
            <a href="/#" onClick={() => setActiveTabIndex(2)}>
              <span className="service-icon">{laundryIcon}</span>Laundry
            </a>
          </li>

          <li className="mid-service-item">
            <a href="/#" onClick={() => setActiveTabIndex(4)}>
              <span className="service-icon">{maintenanceIcon}</span>Maintenance
            </a>
          </li>

          <li className="mid-service-item">
            <a href="/#" onClick={() => setActiveTabIndex(5)}>
              <span className="service-icon">{internetIcon}</span>Internet
            </a>
          </li>
          <li className="mid-service-item">
            <a href="/#" onClick={() => setActiveTabIndex(-1)}>
              <span className="service-icon">{shopIcon}</span>Shop
            </a>
          </li>
        </ul>
      </div>
      {leftScrollBtnVisibility && (
        <button className="sliderBtn right" onClick={onScrollLeft}>
          {rightIcon}
        </button>
      )}
      {intro}
    </div>
  );
};
