import React from "react";
import { SofaCleaningBody } from "./SofaCleaningBody";

export const SofaCleaning = ({ bg }: any) => {
	return (
		<div
			className="sofa-cleaning uk-background-cover"
			style={{ backgroundImage: `url(${bg})` }}
		>
			<div className="uk-section">
				<div className="sofa-cleaning-container uk-container">
					<SofaCleaningBody />
				</div>
			</div>
		</div>
	);
};
