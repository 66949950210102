import React from "react";
import "../components/shop/Shop.scss";
import TemporaryShop from "../components/shop/TemporaryShop";
import { PageFooter } from "../components/footer/PageFooter";

export const ShopPage = () => {
  return (
    <div className="shop-page">
      <TemporaryShop />

      {/* Page footer */}
      <PageFooter />

      {/* <div className="shop-search">
						<ShopSearch />
					</div>
					<div className="shop-nav-container">
						<ShopNav />
					</div>
					<div
						className="uk-grid uk-child-width-1-4@m uk-margin"
						uk-grid="true"
					>
						<ShopCard />
					</div> */}
    </div>
  );
};
