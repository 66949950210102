import React from "react";
import { Link } from "react-router-dom";

export const PageHeader = (props: any) => {
	const content = props.content;
	const { bgImg, title } = content;

	const bg = bgImg;

	return (
		<div
			className="page-header uk-background-cover uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle"
			style={{ backgroundImage: `url(${bg})` }}
		>
			<div className="content uk-container">
				<h1 className="heading heading-overlay">{title}</h1>
				<Link to="/">
					<button className="back-to-home uk-button uk-button-primary">
						Go back to Home
					</button>
				</Link>
			</div>
		</div>
	);
};
