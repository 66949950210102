import React from "react";
import { Listing } from "../HomeSpace";
import ViewPlaceCard from "./ViewPlaceCard";

interface Props {
  listing: Listing;
}

const ViewPlaceCardSlider = (props: Props) => {
  const { listing } = props;
  const {
    thumbnails: images,
    name,
    baths,
    bedrooms,
    area,
    price,
    description,
  } = listing;

  return (
    <div className="view-place-card place-card uk-card uk-card-default">
      <div className="content uk-grid uk-child-width-1-2@s">
        <div className="media uk-card-media-left uk-cover-container">
          <div
            className="slider uk-position-relative uk-visible-toggle uk-light"
            tabIndex={-1}
            uk-slideshow=""
          >
            <ul className="uk-slideshow-items" uk-lightbox="animation: slide">
              {images.map((img: string, index: number) => (
                <li key={index}>
                  <ViewPlaceCard src={img} />
                </li>
              ))}
            </ul>

            <a
              className="uk-position-center-left uk-position-small uk-hidden-hover"
              href="/#"
              uk-slideshow-item="previous"
            >
              <span uk-icon="icon: chevron-left"></span>
            </a>
            <a
              className="uk-position-center-right uk-position-small uk-hidden-hover"
              href="/#"
              uk-slideshow-item="next"
            >
              <span uk-icon="icon: chevron-right"></span>
            </a>
          </div>

          {/* Thumnbnails */}
          {/* <div className="view-card-thumbnails">
            <div
              className="uk-position-relative uk-visible-toggle uk-light"
              tabIndex={-1}
              uk-slider=""
            >
              <ul className="uk-slider-items uk-text-center">
                {images.map((img: string, index: number) => (
                  <li key={index}>
                    <img src={img} alt="" />
                  </li>
                ))}
              </ul>

              <a
                className="uk-position-center-left uk-position-small uk-hidden-hover"
                href="/#"
                uk-slidenav-previous=""
                uk-slider-item="previous"
              >
                {""}
              </a>
              <a
                className="uk-position-center-right uk-position-small uk-hidden-hover"
                href="/#"
                uk-slidenav-next=""
                uk-slider-item="next"
              >
                {""}
              </a>
            </div>
          </div> */}

          <div className="uk-card-badge uk-label">Rent</div>
        </div>
        <div>
          <div className="body uk-card-body">
            <h3 className="address uk-card-title">{name}</h3>
            <h3 className="price">N${price}</h3>
            <div className="options">
              {bedrooms && (
                <p className="beds">
                  <span>{bedrooms}</span>
                  <span className="label">Beds</span>
                </p>
              )}
              {baths && (
                <p className="baths">
                  <span>{baths}</span>
                  <span className="label">Baths</span>
                </p>
              )}
              {area && (
                <p className="area">
                  <span>{area}</span>
                  <span className="label">Sq. Ft.</span>
                </p>
              )}
            </div>
            <div className="description" style={{ marginTop: "20px" }}>
              <ul>
                {description &&
                  description.map((descr: string, index: number) => (
                    <li key={index} style={{ margin: 0 }}>
                      {descr}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPlaceCardSlider;
