import React, { useState } from "react";
import { useHistory } from "react-router";
import MobxHomeSpaceStore from "../../../stores/HomeSpaceStore";
import { Agent } from "../HomeSpace";

interface Props {
  agent: Agent;
}

export const AgentCard = (props: Props) => {
  const [store] = useState(() => MobxHomeSpaceStore);

  const agent = props.agent;
  const {
    id,
    name,
    cellphone,
    company,
    email,
    office,
    profile_pic,
    social_links,
    occupation,
  } = agent;
  const history = useHistory();

  const viewAgentListings = () => {
    store.setAgent(agent);
    history.push("/agents-listings/" + id);
  };

  return (
    <div className="agent-card uk-card uk-card-default uk-card-small uk-card-hover">
      <div className="image uk-card-media-top">
        <img src={profile_pic} alt="" />
      </div>
      <div className="body uk-card-body">
        <h3 className="agent-name uk-card-title">{name}</h3>
        {company?.name && (
          <p
            className="company name"
            style={{ marginBottom: "10px", textTransform: "uppercase" }}
          >
            {company?.name}
          </p>
        )}
        {company?.website && (
          <a
            href={company?.website}
            target="_blank"
            rel="noreferrer"
            className="company website"
          >
            <span className="prefix">W: </span>
            {company?.website}
          </a>
        )}

        {email && (
          <a
            href={"mailto:" + email}
            target="_blank"
            rel="noreferrer"
            className="email"
          >
            <span className="prefix">E: </span>
            {email}
          </a>
        )}
        {cellphone && (
          <p className="cellphone">
            <span className="prefix">P: </span>
            {cellphone}
          </p>
        )}
        {office && (
          <p className="cellphone">
            <span className="prefix">O: </span>
            {office}
          </p>
        )}
      </div>
      <div className="footer uk-card-footer">
        <button
          className="link uk-button uk-button-text"
          onClick={viewAgentListings}
        >
          View Listings
        </button>
        <div className="links">
          {social_links?.facebook && (
            <a
              href={social_links?.facebook}
              target="_blank"
              rel="noreferrer"
              className="uk-icon-button uk-margin-small-right"
            >
              <span uk-icon="facebook" style={{ color: "#3b5998" }}></span>
            </a>
          )}
          {social_links?.twitter && (
            <a
              href={social_links?.twitter}
              target="_blank"
              rel="noreferrer"
              className="uk-icon-button uk-margin-small-right"
            >
              <span uk-icon="twitter" style={{ color: "#55acee" }}></span>
            </a>
          )}
          {social_links?.instagram && (
            <a
              href={social_links?.instagram}
              target="_blank"
              rel="noreferrer"
              className="uk-icon-button"
            >
              <span uk-icon="instagram" style={{ color: "#c049a7" }}></span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
