import React from "react";
import { Link } from "react-router-dom";
import { CardC } from "../shared/CardC";

export const ShopIntro = () => {
  const title = "Kosha store";
  const descr = "Buy & order all your home essentials goods";

  return (
    <div className="shop-intro">
      <div className="uk-card uk-card-default uk-card-body uk-text-center">
        <div className="uk-grid uk-child-width-1-1@m">
          <div>
            <h3 className="uk-card-title">{title}</h3>
            <p>{descr}</p>
            <div className="buttons">
              <Link to="/shop" className="uk-button uk-button-primary">
                Order now
              </Link>
            </div>
          </div>
        </div>

        {/* <div className="product-list">
					<div className="uk-grid uk-child-width-1-3@m">
						<CardC />
					</div>
				</div> */}
      </div>
    </div>
  );
};
