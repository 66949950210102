import React from "react";
import { CompanyStatements } from "../components/about/CompanyStatements";
import { Team } from "../components/about/Team";
import { PageFooter } from "../components/footer/PageFooter";
import { PageHeader } from "../components/shared/PageHeader";

export const AboutPage = () => {
  // Content of the header
  const headerContent = {
    bgImg:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    title: "About Us",
  };

  return (
    <>
      {/* Page header */}
      <PageHeader content={headerContent} />

      {/* Company statements */}
      <CompanyStatements />

      {/* Company team */}
      <Team />

      {/* Page footer */}
      <PageFooter />
    </>
  );
};
