import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import CleaningStore from "../../stores/CleaningStore";

export const BookingTaskItem = (props: any) => {
  const { extra } = props;
  const checkIcon = <FontAwesomeIcon icon={faCheck} />;

  // Store
  const store = CleaningStore;

  useEffect(() => {
    return () => {
      store.removeExtra(extra);
    };
  }, []);

  const handleChange = (e: any) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (value) store.addExtras(extra);
    else store.removeExtra(extra);
  };

  return (
    <label className="booking-task-item">
      <input type="checkbox" onChange={handleChange} />
      <div className="item-icon">
        <span className="check">{checkIcon}</span>
        <img src={extra.icon} alt="" />
      </div>
      <div className="item-name uk-text-center">
        <p>{extra.name}</p>
      </div>
    </label>
  );
};
