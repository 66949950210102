import React, { useEffect, useState } from "react";
import { db } from "../../services/firebase";
import { BookingAppointmentDetails } from "./BookingAppointmentDetails";
import { BookingExtras } from "./BookingExtras";
import { BookingRegularity } from "./BookingRegularity";
import { CategoryQuantity } from "./CategoryQuantity";
import { CleaningCategories } from "./CleaningCategories";

export const Indoors = () => {
  const collectionName = "indoorBookingCategories";

  const [indoorCategories, setIndoorCategories] = useState([]);

  useEffect(() => {
    getIndoorCategories();
    return () => {};
  }, []);

  const getIndoorCategories = async () => {
    const $db = await db.collection(collectionName).orderBy("name");
    return $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      setIndoorCategories(docs);
    });
  };
  return (
    <div className="indoors">
      {/* Cleaning Categories  */}
      <CleaningCategories categories={indoorCategories} />

      {/* Quantities */}
      <CategoryQuantity />

      {/* Extras */}
      <BookingExtras />

      {/* Choose a Pro */}
      {/* <ChooseAPro /> */}

      {/* Regularity */}
      <BookingRegularity />

      {/* Booking Appointment details */}
      <BookingAppointmentDetails />
    </div>
  );
};
