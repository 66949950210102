import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Link } from 'react-router-dom';

export const SignupPage = () => {
  const facebookIcon = <FontAwesomeIcon icon={faFacebookF} />;
  const googleIcon = <FontAwesomeIcon icon={faGoogle} />;
	return (
		<div className="sign-up-page">
			<div className="uk-section">
				<div className="uk-container uk-text-center">
					{/* Brand Logo */}
					<div className="brand uk-margin">
						<img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
					</div>

					{/* sign-up Card */}
					<div className="sign-up-card uk-card uk-card-default">
						<div className="uk-card-header">
							<h3 className="uk-card-title uk-margin-remove-bottom">Sign Up</h3>
						</div>
						<div className="uk-card-body">
							<form className="uk-form-stacked">
              <div className="uk-margin">
									<label className="uk-form-label uk-text-left" htmlFor="sign-up-fullname">
										Full name
									</label>
									<div className="uk-form-controls">
										<input className="uk-input" id="sign-up-fullname" type="text" />
									</div>
								</div>
								<div className="uk-margin">
									<label className="uk-form-label uk-text-left" htmlFor="sign-up-email">
										Email
									</label>
									<div className="uk-form-controls">
										<input className="uk-input" id="sign-up-email" type="email" />
									</div>
								</div>

								<div className="uk-margin">
									<label className="uk-form-label uk-text-left" htmlFor="sign-up-password">
										Password
									</label>
									<div className="uk-form-controls">
										<input
											className="uk-input"
											id="sign-up-password"
											type="password"
										/>
									</div>
								</div>

								<div className="uk-margin">
									<button className="sign-up uk-button uk-button-primary uk-width-1-1">
										Sign up
									</button>
								</div>
							</form>
						</div>
					</div>

					{/* Sign up */}
					<div className="uk-margin sign-in">
						<p className="uk-text-center">
						Already have an account yet? <Link to="/login">Sign in</Link>
						</p>
					</div>

          {/* Sign up */}
					<div className="social-sign-up uk-margin">
						<button className="loginWFB uk-button uk-button-primary">{facebookIcon} with FACEBOOK</button>
            <button className="loginWG uk-button uk-button-danger">{googleIcon} with GOOGLE</button>
					</div>
				</div>
			</div>
		</div>
	);
}
