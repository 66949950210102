import React, { useEffect, useState } from "react";
import { PageFooter } from "../../footer/PageFooter";
import AgentInfo from "./AgentInfo";
import { PlaceCard } from "../PlaceCard";
import ListingsSlider from "./ListingsSlider";
import { db } from "../../../services/firebase";
import { Agent, Listing } from "../HomeSpace";
import { useParams } from "react-router-dom";
import MobxHomeSpaceStore from "../../../stores/HomeSpaceStore";
import ContactAgentModal from "../ContactAgentModal";
import ViewPropertyModal from "../view_property/ViewPropertyModal";

const AgentsListingsPage = () => {
  const [store] = useState(() => MobxHomeSpaceStore);

  // Store data
  const [listings, setListings] = useState<Listing[]>();
  const [transactions, setTransactions] = useState<Listing[]>();
  const [agent, setAgent] = useState<Agent>();
  const params: { agentID: string } = useParams();
  const agentID = params.agentID;

  useEffect(() => {
    getAgent();
    getListings();
    getTransactions();
    return () => {
      setListings([]);
    };
  }, []);

  const getAgent = async () => {
    if (store.getAgent.id) {
      // Get from store
      setAgent(store.getAgent);
    } else {
      // Else read from database
      const $doc = await db
        .collection(store.firebaseAgentsCollection)
        .doc(agentID);
      return $doc
        .get()
        .then((doc) => {
          if (doc.exists) {
            const $doc: Agent = {
              id: doc.id,
              name: "",
              cellphone: "",
              email: "",
              profile_pic: "",
              ...doc.data(),
            };
            store.setAgent($doc);
            setAgent($doc);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  };

  const getListings = async () => {
    if (agentID) {
      const $db = await db
        .collection(store.firebaseListingsCollection)
        .where("agent", "==", agentID);
      return $db.onSnapshot((querySnapshot: any) => {
        const docs = querySnapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() };
        });
        setListings(docs);
      });
    }
  };

  const getTransactions = async () => {
    if (agentID === "false") {
      const $db = await db
        .collection(store.firebaseListingsCollection)
        .where("agent", "==", agentID);
      return $db.onSnapshot((querySnapshot: any) => {
        const docs = querySnapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() };
        });
        setTransactions(docs);
      });
    }
  };

  const dispListings = listings?.map((listing) => (
    <div key={listing.id}>
      <PlaceCard listing={listing} />
    </div>
  ));

  return (
    <div>
      <div className="agents-listing-page">
        {/* Agent info */}
        {agent && <AgentInfo agent={agent} />}

        {/* Active Listings */}
        {listings && (
          <div className="active-listings listings">
            <div className="uk-section uk-section-default">
              <div className="uk-container">
                <h3 className="section-title">{agent?.name}’s Listings</h3>
                <div
                  className="uk-grid uk-grid-medium uk-child-width-1-1@s uk-child-width-1-2@m uk-margin-large"
                  uk-grid="true"
                >
                  {dispListings}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Sold Listings */}
        {transactions && (
          <div className="sold-listings listings">
            <div className="uk-section uk-section-secondary">
              <div className="uk-container">
                <h3 className="section-title">{agent?.name}’s Transactions</h3>
                <ListingsSlider />
              </div>
            </div>
          </div>
        )}
      </div>
      <PageFooter />

      {/* Modal */}
      <ViewPropertyModal />
      <ContactAgentModal />
    </div>
  );
};

export default AgentsListingsPage;
