import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";
import swal from "sweetalert";

export const Contact = () => {
  const API_PATH = "https://koshaservices.com/php/contact.php?";

  const sendIcon = <FontAwesomeIcon icon={faPaperPlane} />;

  let contactForm: any = "";
  let _name: any = "";
  let _email: any = "";
  let _comments: any = "";

  const handleSubmitContact = (event: any) => {
    event.preventDefault();
    const recipient = "info@koshaservices.com";
    const alt_recipient = "hamukotol@koshaservices.com";
    const fullname = _name.value;
    const email = _email.value;
    const message = _comments.value;

    contactForm.reset();

    const url = `email=${escape(email)}&message=${escape(
      message
    )}&fullname=${escape(fullname)}&recipient=${escape(
      recipient
    )}&alt_recipient=${escape(alt_recipient)}`;

    // Post mail
    postMail(API_PATH + url);
  };

  const postMail = (path: string) => {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        swal(
          "Thank You!",
          "Your message has been sent to Kosha Services.",
          "success"
        );
      }
      if (this.readyState === 4 && this.status === 500) {
        swal(
          "Error contacting Kosha Services",
          "Please use email/phone number to contact us!",
          "error"
        );
      }
    };

    xhttp.open("GET", path, true);
    xhttp.send();
  };

  return (
    <div>
      <form ref={(form) => (contactForm = form)} onSubmit={handleSubmitContact}>
        <fieldset className="uk-fieldset uk-text-center">
          <legend className="header uk-legend uk-text-left">Contact Us</legend>
          <div className="uk-margin">
            <input
              className="uk-input"
              ref={(input) => (_name = input)}
              type="text"
              placeholder="Name"
              aria-label="Contact us Name"
            />
          </div>

          <div className="uk-margin">
            <input
              className="uk-input"
              ref={(input) => (_email = input)}
              type="email"
              placeholder="Email"
              aria-label="Contact us Email"
            />
          </div>

          <div className="uk-margin">
            <textarea
              className="uk-textarea"
              ref={(input) => (_comments = input)}
              rows={5}
              placeholder="Comments"
              aria-label="Contact us comments"
            ></textarea>
          </div>

          <button
            className="uk-button uk-button-default"
            aria-label="Send message"
            type="submit"
          >
            Send Message {sendIcon}
          </button>
        </fieldset>
      </form>
    </div>
  );
};
