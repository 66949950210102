import React from "react";
import { BookingTabItem } from "./BookingTabItem";

export const BookingTabs = (props: any) => {
  const setActiveTab = props.setActiveTab;
  const activeIndex = props.activeTabIndex;

  const tabs = ["Indoor", "Outdoor", "Subscriptions"];

  const tabsList = tabs.map((tab, key) => (
    <BookingTabItem
      key={key}
      index={key}
      tab={tab}
      setActiveTab={setActiveTab}
      activeIndex={activeIndex}
    />
  ));

  return (
    <div className="booking-tabs">
      <div className="uk-card uk-card-default uk-card-small">
        <ul className="booking-category-tabs uk-flex-center" uk-tab="true">
          {tabsList}
        </ul>
      </div>
    </div>
  );
};
