import React from "react";
import laundryStore from "../../stores/LaundryStore";
import { PriceCleaningCard } from "./PriceCleaningCard";

export const CarpetCleaningBody = () => {
  const packages = [
    {
      size: "Large sized",
      price: 350,
    },
    {
      size: "Medium sized",
      price: 250,
    },
  ];

  const dispMatList = packages.map((item, index) => (
    <PriceCleaningCard key={index} size={item.size} price={item.price} />
  ));

  const setModalName = () => {
    const store = laundryStore;
    store.setModalName("Carpet Care");
  };

  return (
    <div className="uk-grid uk-grid-large uk-child-width-1-2@s" uk-grid={""}>
      <div className="carpet-intro">
        {/* Display sectoin header */}
        <h1 className="laundry-header heading">
          <span>Carpet</span> Care
        </h1>

        <h3 className="service-description">
          Wet and Dry <br /> On site cleaning
        </h3>

        <div>
          <button
            className="bookBtn uk-button uk-button-primary"
            uk-toggle="target: #laundry-modal"
            onClick={setModalName}
          >
            Book Now
          </button>
        </div>
      </div>

      <div>
        {/* Display laundry Prices */}
        <div className="cleaning-packages">{dispMatList}</div>
      </div>
    </div>
  );
};
