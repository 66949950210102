import React from "react";
import { LaundryCleaningCard } from "./LaundryCleaningCard";

export const LaundryCleaning = ({ bg }: any) => {
  return (
    <div
      className="laundry-cleaning uk-background-cover uk-panel"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="uk-section">
        <div className="laundry-cleaning-container uk-container">
          {/* Display sectoin header */}
          <h1 className="laundry-header heading">
            <span>Clothing</span> Care
          </h1>

          {/* Display laundry Prices */}
          <LaundryCleaningCard />
        </div>
      </div>
    </div>
  );
};
