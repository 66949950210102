import React from 'react'

export const CardCorporateLogo = (props: any) => {
  const company = props.item;
  const {thumbnail} = company;
  return (
    <div className="company uk-text-center">
      <img src={thumbnail} alt=""/>
    </div>
  )
}
