import React from "react";
import { MattressCleaningBody } from "./MattressCleaningBody";

export const MattressCleaning = ({ bg }: any) => {
  return (
    <div
      className="mattress-cleaning uk-background-cover"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="uk-section">
        <div className="mattress-cleaning-container uk-container">
          <MattressCleaningBody />
        </div>
      </div>
    </div>
  );
};
