import React from "react";
import ViewSearchResults from "./search_results/ViewSearchResults";

const DisplaySearchResults = () => {
  /* Search results */
  return (
    <div className="uk-section uk-section-muted">
      <div className="uk-container uk-container-xlarge">
        <ViewSearchResults />
      </div>
    </div>
  );
};

export default DisplaySearchResults;
