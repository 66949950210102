import { observer } from "mobx-react";
import React from "react";
import { CleaningCategory } from "../../interfaces/CleaningInterfaces";
import cleaningStore from "../../stores/CleaningStore";
import { QuantityInputField } from "./QuantityInputField";

const store = cleaningStore;
//
const Categories = observer((props: any) => {
  const { store } = props;

  const summary = store.categories.map(
    (item: CleaningCategory, index: number) => (
      <div key={item.id}>
        <QuantityInputField category={item} index={index} />
      </div>
    )
  );

  const disp = store.totalSelectedCategories ? (
    <div
      className="quantity-list uk-grid uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-5@m"
      uk-grid="true"
    >
      {summary}
    </div>
  ) : (
    <p className="note uk-margin-medium-bottom">
      {" "}
      Select the categories above{" "}
    </p>
  );

  return <>{disp}</>;
});

export const CategoryQuantity = () => {
  return (
    <>
      <div className="category-quantity uk-margin">
        <h3 className="heading">
          Specify <span>Quantity</span>
        </h3>

        {/* Display quantity */}
        <Categories store={store}></Categories>
      </div>
    </>
  );
};
