import base from "./initAirtable";

// List records
export const listRecords = (table: string, view: string) => {
  const records = base
    .table(table)
    .select({
      view: view,
    })
    .firstPage((err: any, records: any) => {
      if (err) {
        console.error(err);
        return;
      }

      return records;
    });

  return records;
};

// Create record
export const createRecords = (table: string, record: any) => {
  return base.table(table).create(record);
  // .then((records: any) => {
  //   records.forEach(function (record: any) {
  //     console.log(record.getId());
  //   });
  // })
  // .catch((err: any) => {
  //   console.error(err);
  // });
};
