import React from "react";
import { Agent } from "../HomeSpace";

interface Props {
  agent: Agent;
}

const AgentInfo = (props: Props) => {
  const agent = props.agent;
  const {
    id,
    name,
    cellphone,
    company,
    email,
    office,
    profile_pic,
    social_links,
    occupation,
  } = agent;

  return (
    <div className="agent-info">
      <div className="uk-section uk-section-muted">
        <div className="uk-container">
          <div className="uk-grid uk-child-width-expand@s">
            <div>
              <div className="agent-thumnail">
                <img src={profile_pic} alt="" />
              </div>
            </div>
            <div>
              <div className="agent-brief">
                <h2 className="name">{name}</h2>
                <p className="address">{company?.name}</p>
                <p className="address">{company?.website}</p>
                {email && (
                  <a className="email" href={"mailto: " + email}>
                    <span uk-icon="icon: mail"></span>
                    {email}
                  </a>
                )}

                {cellphone && (
                  <a className="phone" href="/#">
                    <span uk-icon="icon: receiver"></span>
                    M: {cellphone}
                  </a>
                )}
                {office && (
                  <a className="phone" href="/#">
                    <span uk-icon="icon: receiver"></span>
                    O: {office}
                  </a>
                )}
                <button
                  className="contact uk-button uk-button-primary"
                  uk-toggle="target: #modal-contact-agent"
                  type="button"
                >
                  Contact {name}
                </button>
              </div>
            </div>
          </div>

          {/* <div className="agent-bio">
            <ul uk-accordion="">
              <li>
                <a className="title uk-accordion-title" href="/#">
                  About {name}
                </a>
                <div className="uk-accordion-content">
                  <p className="bio">
                    Since 1999, I spent the first 4 years of my career doubling
                    as a mortgage broker and real estate agent. The knowledge
                    and background as a mortgage broker allow me to analyze and
                    structure every aspect of a transaction. Having a B.S. in
                    Business Administration with an emphasis in management helps
                    the real estate process of coordinating people, paperwork,
                    and properties. I have a number of residential renovation
                    projects. My knowledge of construction and design is a great
                    complement in assisting buyers and sellers. I have a wealth
                    of resources to provide for any aspect of work needed in
                    buying/selling a home. My wife and I reside in Orinda with
                    our three beautiful children. We both attended Miramonte
                    School. We are involved in the community and know just about
                    everything regarding Lamorinda and our surrounding
                    communities. As a top producer, I am well educated on the
                    trends of our marketplace in the dynamic Bay Area. As your
                    real estate professional, my mission is to dedicate myself
                    to making the process of buying or selling your home as easy
                    and satisfying as possible. At all times I will respect the
                    needs of each and every client I am commissioned to work
                    for; I will remain honest and forthright and will place your
                    best interest in the highest regard throughout the process
                    of our transaction. I will exceed your expectations!
                  </p>
                </div>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AgentInfo;
