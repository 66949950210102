import React from "react";
import { faUsers, faBullseye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const InfoCard = () => {
  const usersIcon = <FontAwesomeIcon icon={faUsers} />;
  const commitmentIcon = <FontAwesomeIcon icon={faBullseye} />;

  return (
    <>
      <div>
        <div className="card-b uk-card uk-card-default uk-card-body uk-card-hover">
          <div className="icon">
            <span>{usersIcon}</span>
          </div>
          <h3 className="uk-card-title">About Us</h3>
          <p>
            We are a Home & Office Space Care company in pursuit of delivering
            better living experiences by caring for homes, offices, clothes and
            supplying essential goods
          </p>
          <Link to="/about" className="uk-link">
            Read more
          </Link>
        </div>
      </div>
      <div>
        <div className="card-b uk-card uk-card-default uk-card-body uk-card-hover">
          <div className="icon">
            <span>{commitmentIcon}</span>
          </div>
          <h3 className="uk-card-title">What We Value</h3>
          <p>
            Kosha is created on the foundation that we need to be different, the
            need to create connected and better experiences that are memorable.
            Our business is adaptable and grows on the by the support of our
            people, customers, and partners
          </p>
          <Link to="/about" className="uk-link">
            Read more
          </Link>
        </div>
      </div>
      <div>
        <div className="news-letter-card uk-card uk-card-secondary uk-card-body">
          <h3 className="uk-card-title">Newsletter</h3>
          <p>Get latest offers and information from our executive team</p>
          {/* <form>
            <input
              className="subscribe-input uk-input"
              type="email"
              placeholder="Email"
            />

            <button className="uk-button uk-button-default" type="submit">
              Subscribe
            </button>
          </form> */}

          {/* <!-- Begin Mailchimp Signup Form --> */}
          <form
            action="https://koshaservices.us17.list-manage.com/subscribe/post?u=0d7381b2013867338a986b144&amp;id=0e5679f306"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
          >
            <input
              type="email"
              name="EMAIL"
              className="subscribe-input uk-input"
              id="mce-EMAIL"
              placeholder="email address"
              required
            />
            {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
            <button className="uk-button uk-button-default" type="submit">
              Subscribe
            </button>
          </form>

          <h3 className="uk-card-title uk-margin-medium-top">Downloads</h3>
          <ol className="downloads">
            <li>
              <a href="/#" className="uk-link">
                3RD Anniversary CEO Letter-2021
              </a>
            </li>
            <li>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/kosha-439e6.appspot.com/o/downloads%2FKOSHA%20COMPANY%20PROFILE%202019_2020.pdf?alt=media&token=49014330-8ca5-4318-970f-32d68df396af"
                target="_blank"
                rel="noreferrer"
                className="uk-link"
              >
                Company Profile 2020/2021
              </a>
            </li>
            <li>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/kosha-439e6.appspot.com/o/downloads%2FBlack%20Friday%20Kosha%20Listings.pdf?alt=media&token=0144a96c-b686-4da3-95d7-fabf39a7b750"
                target="_blank"
                rel="noreferrer"
                className="uk-link"
              >
                Black Friday Special (2020/2021)
              </a>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};
