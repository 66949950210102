import { observer } from "mobx-react";
import React, { useState } from "react";
import swal from "sweetalert";
import {
  BookingCustomerDetails,
  BookingDetails,
} from "../../interfaces/BookingInterfaces";
import { createRecords } from "../../services/airtableFunctions";
declare var UIkit: any;

export const BookingModal = observer((props: any) => {
  // Airtable constants
  const table = "Maintenance";
  const { store } = props;
  const modalName = store.getModalName;

  const initCustomerDetails: BookingCustomerDetails = {
    firstname: "",
    lastname: "",
    email: "",
    cellphone: "",
    service: "",
    address: "",
  };

  const initBookingDetails: BookingDetails = {
    taskHours: 0,
    date: Date.now(),
    taskDescription: "",
    taskAddress: "",
  };

  const [customerDetails, setCustomerDetails] = useState(initCustomerDetails);
  const [bookingDetails, setBookingDetails] = useState(initBookingDetails);
  const [dispError, setDispErrors] = useState([<></>]);
  const applicableHours = [2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7];

  const onQuoteRequestSent = (e: any) => {
    e.preventDefault();

    // Validate form
    const errorsArray = validate();
    if (!validate().length) {
      const customerOrder: any = {
        ...customerDetails,
        ...bookingDetails,
        service: modalName,
      };

      // Hide modal
      const modal = UIkit.modal("#booking-modal");
      if (modal) {
        modal.hide();
      }

      createRecords(table, customerOrder)
        .then((records: any) => {
          if (records.length) {
            records.forEach((record: any) => {
              swal(
                "Request Sent!",
                `Your Request number is #booking:${record.getId()}`,
                "success"
              );
            });
          } else {
            console.log("Not array");
            swal(
              "Request Sent!",
              `Your Request number is #booking:${records.getId()}`,
              "success"
            );
          }

          // Reset booking form
          setCustomerDetails(initCustomerDetails);
          setBookingDetails(initBookingDetails);
        })
        .catch((err: any) => {
          console.error(err);
          swal("Error sending Request!", "Please contact admin", "error");
        });
    } else {
      const errorsDisp = errorsArray.map((errorMsg: string, key: number) => {
        return (
          <p key={key} style={{ color: "red" }}>
            {errorMsg}
          </p>
        );
      });
      setDispErrors(errorsDisp);
    }
  };

  const validate = () => {
    const validationErrors = [];
    // Validate name
    if (!customerDetails.firstname.length || !customerDetails.lastname.length)
      validationErrors.push("Please fill in your name");

    // Validate email
    if (!validateEmail(customerDetails.email))
      validationErrors.push("Invalid email");

    // validate cellphone
    if (!customerDetails.cellphone.length)
      validationErrors.push("Invalid email");

    return validationErrors;
  };

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const dispHours = applicableHours.map((hour, key) => (
    <option value={hour} key={key}>
      {hour} hours
    </option>
  ));
  return (
    <div id="booking-modal" className="modal uk-flex-top" uk-modal="true">
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button
          className="uk-modal-close-default"
          type="button"
          uk-close="true"
        ></button>

        <div className="modal-header">
          <h2 className="title uk-modal-title">
            Book for: <span>{modalName}</span>
          </h2>
        </div>
        <div className="modal-content">
          <form
            className="modal-form uk-grid-small"
            uk-grid="true"
            onSubmit={(e) => onQuoteRequestSent(e)}
          >
            <div className="uk-width-1-2@s">
              <div>
                <label className="uk-form-label" htmlFor="booking-firstname">
                  First Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-firstname"
                    type="text"
                    required
                    value={customerDetails.firstname}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        firstname: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div>
                <label className="uk-form-label" htmlFor="booking-lastname">
                  Last Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-lastname"
                    type="text"
                    required
                    value={customerDetails.lastname}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        lastname: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@s">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="booking-email">
                  Email
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-email"
                    type="email"
                    required
                    value={customerDetails.email}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@s">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="booking-cellphone">
                  Cellphone
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-cellphone"
                    type="text"
                    required
                    value={customerDetails.cellphone}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        cellphone: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@s">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="booking-address">
                  Personal Address
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-address"
                    type="text"
                    value={customerDetails.address}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        address: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-margin " />
            <div className="uk-width-1-1@s">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="booking-site-address">
                  Site Address (Residential or Business site address that needs
                  our services)
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-site-address"
                    type="text"
                    value={customerDetails.address}
                    onChange={(e: any) =>
                      setBookingDetails({
                        ...bookingDetails,
                        taskAddress: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="uk-width-1-2@s">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="booking-date">
                  When should we come?
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    type="date"
                    id="booking-date"
                    value={bookingDetails.date}
                    onChange={(e: any) =>
                      setBookingDetails({
                        ...bookingDetails,
                        date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="booking-hours">
                  How many hours would you like to book?
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    id="booking-hours"
                    value={bookingDetails.taskHours}
                    onChange={(e: any) =>
                      setBookingDetails({
                        ...bookingDetails,
                        taskHours: e.target.value,
                      })
                    }
                  >
                    {dispHours}
                  </select>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1@s">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="booking-description">
                  Tell us about the job (Please describe the job in details.)
                </label>
                <textarea
                  id="booking-description"
                  className="uk-textarea"
                  rows={5}
                  required
                  value={bookingDetails.taskDescription}
                  onChange={(e: any) =>
                    setBookingDetails({
                      ...bookingDetails,
                      taskDescription: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
            <div className="uk-width-1-1">{dispError}</div>
            <div className="uk-width-1-1">
              <div className="uk-margin uk-text-center">
                <button
                  className="submit uk-button uk-button-primary"
                  type="submit"
                  onClick={(e) => onQuoteRequestSent(e)}
                >
                  Get Quote
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});
