import React, { useState } from "react";
import { BookingCustomerDetails } from "../../interfaces/BookingInterfaces";
import {
  ProductCheckbox,
  TemporaryShopTabsContent,
} from "./TemporaryShopTabsContent";
import { createRecords } from "../../services/airtableFunctions";
import swal from "sweetalert";
import { ValidateEmail } from "../../services/ValidateEmail";
declare var UIkit: any;

const TemporaryShop = (props: any) => {
  // Stores customer details
  const initCustomerDetails: BookingCustomerDetails = {
    firstname: "",
    lastname: "",
    email: "",
    cellphone: "",
    town: "",
    service: "Shop",
  };
  const [customerDetails, setCustomerDetails] = useState(initCustomerDetails);
  // Validation errors
  const [dispError, setDispErrors] = useState([<></>]);

  // Airtable constants
  const table = "Shop";

  // Shop components
  const [kitchenProducts, setKitchenProducts] = useState([
    {
      name: "Handy Andy",
      isChecked: false,
    },
    {
      name: "Dishwashing Liquid",
      isChecked: false,
    },
    {
      name: "Domestos/Bleach",
      isChecked: false,
    },
  ]);

  const [bathroomProducts, setBathroomProducts] = useState([
    {
      name: "Domestos",
      isChecked: false,
    },
    {
      name: "Toilet Freshener Spray",
      isChecked: false,
    },
    {
      name: "Toilet Paper Pack",
      isChecked: false,
    },
    {
      name: "Mirror Cleaner",
      isChecked: false,
    },
  ]);

  const [officeProducts, setOfficeProducts] = useState([
    {
      name: "Coffee",
      isChecked: false,
    },
    {
      name: "Sugar",
      isChecked: false,
    },
    {
      name: "Tea",
      isChecked: false,
    },
    {
      name: "Toilet Paper",
      isChecked: false,
    },
    {
      name: "Office Stationary",
      isChecked: false,
    },
  ]);

  const [generalProducts, setGeneralProducts] = useState([
    {
      name: "Washing Powder",
      isChecked: false,
    },
    {
      name: "Sta-Soft",
      isChecked: false,
    },
  ]);

  // On form submit
  const onQuoteRequest = (e: any) => {
    e.preventDefault();

    // Validate form
    const errorsArray = validate();
    if (!validate().length) {
      const customerOrder = {
        ...customerDetails,
        products: getBookingSummary(),
      };

      // // Hide modal
      // const modal = UIkit.modal("#laundry-modal");
      // if (modal) {
      //   modal.hide();
      // }

      createRecords(table, customerOrder)
        .then((records: any) => {
          if (records.length) {
            records.forEach((record: any) => {
              swal(
                "Request Sent!",
                `Your Request number is #shop${record.getId()}`,
                "success"
              );
            });
          } else {
            console.log("Not array");
            swal(
              "Request Sent!",
              `Your Request number is #shop${records.getId()}`,
              "success"
            );
          }

          // Reset booking form
          setCustomerDetails(initCustomerDetails);
        })
        .catch((err: any) => {
          console.error(err);
          swal("Error sending Request!", "Please contact admin", "error");
        });
    } else {
      const errorsDisp = errorsArray.map((errorMsg: string, key: number) => {
        return (
          <p key={key} style={{ color: "red" }}>
            {errorMsg}
          </p>
        );
      });
      setDispErrors(errorsDisp);
    }
  };

  const validate = () => {
    const validationErrors = [];
    // Validate name
    if (!customerDetails.firstname.length || !customerDetails.lastname.length)
      validationErrors.push("Please fill in your name");

    // Validate email
    if (!ValidateEmail(customerDetails.email))
      validationErrors.push("Invalid email");

    // validate cellphone
    if (!customerDetails.cellphone.length)
      validationErrors.push("Invalid email");

    return validationErrors;
  };

  const getBookingSummary = () => {
    const interestedKitchenProductsNames = kitchenProducts
      .filter((item: ProductCheckbox) => item.isChecked)
      .map((item) => `${item.name}`)
      .join(";");
    const interestedBathroomProductsNames = bathroomProducts
      .filter((item: ProductCheckbox) => item.isChecked)
      .map((item) => `${item.name}`)
      .join(";");
    const interestedOfficeProductsNames = officeProducts
      .filter((item: ProductCheckbox) => item.isChecked)
      .map((item) => `${item.name}`)
      .join(";");

    return `${interestedKitchenProductsNames} \n ${interestedBathroomProductsNames}\n${interestedOfficeProductsNames}`;
  };

  return (
    <div className="uk-section">
      <div className="uk-container">
        <div className="uk-card uk-card-default uk-card-large uk-card-body">
          <h3 className="uk-card-title">Order your home essentials with us!</h3>
          <form
            className="uk-grid"
            uk-grid=""
            onSubmit={(e) => onQuoteRequest(e)}
          >
            <div className="uk-width-1-2@s">
              <div>
                <label className="uk-form-label" htmlFor="booking-firstname">
                  Firstname
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-firstname"
                    type="text"
                    value={customerDetails.firstname}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        firstname: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div>
                <label className="uk-form-label" htmlFor="booking-lastname">
                  Lastname
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-lastname"
                    type="text"
                    value={customerDetails.lastname}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        lastname: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div>
                <label className="uk-form-label" htmlFor="booking-email">
                  Email
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-email"
                    type="email"
                    value={customerDetails.email}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div>
                <label className="uk-form-label" htmlFor="booking-cellphone">
                  Cellphone
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="booking-cellphone"
                    type="text"
                    value={customerDetails.cellphone}
                    onChange={(e: any) =>
                      setCustomerDetails({
                        ...customerDetails,
                        cellphone: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1">
              <label className="uk-form-label" htmlFor="town">
                Town/City
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  name="town"
                  type="text"
                  placeholder="Town/City"
                  value={customerDetails.town}
                  onChange={(e: any) =>
                    setCustomerDetails({
                      ...customerDetails,
                      town: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <label className="uk-form-label" htmlFor="town">
                Special Request (If any)
              </label>
              <div className="uk-form-controls">
                <textarea
                  className="uk-textarea"
                  name="town"
                  rows={5}
                  placeholder="Town/City"
                  value={customerDetails.comments}
                  onChange={(e: any) =>
                    setCustomerDetails({
                      ...customerDetails,
                      comments: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <TemporaryShopTabsContent
                kitchenProducts={kitchenProducts}
                setKitchenProducts={setKitchenProducts}
                bathroomProducts={bathroomProducts}
                setBathroomProducts={setBathroomProducts}
                officeProducts={officeProducts}
                setOfficeProducts={setOfficeProducts}
                generalProducts={generalProducts}
                setGeneralProducts={setGeneralProducts}
              />
            </div>

            <div className="uk-width-1-1">{dispError}</div>

            <div className="uk-width-1-1">
              <button className="uk-button uk-button-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TemporaryShop;
