import React, { useState } from "react";
import { BookingTabs } from "./BookingTabs";
// import { ChooseAPro } from "./ChooseAPro";
import { Indoors } from "./Indoors";
import { Outdoors } from "./Outdoors";
import { Subscriptions } from "./Subscriptions";

export const BookingForm = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const disp = () => {
    if (activeTabIndex === 1) {
      return <Outdoors />;
    } else if (activeTabIndex === 2) {
      return <Subscriptions />;
    } else {
      return <Indoors />;
    }
  };

  return (
    <div className="booking-form">
      {/* Cleaning tabs */}
      <BookingTabs
        activeTabIndex={activeTabIndex}
        setActiveTab={setActiveTabIndex}
      />

      {disp()}
    </div>
  );
};
