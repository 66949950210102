import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { CleaningCategory } from "../../interfaces/CleaningInterfaces";
import CleaningStore from "../../stores/CleaningStore";

export const CategoryCard = (props: any) => {
	const category: CleaningCategory = props.item;
	const checkIcon = <FontAwesomeIcon icon={faCheck} />;

	// Store
	const store = CleaningStore;

	useEffect(() => {
		return () => {
			store.removeCategory(category);
		};
	}, []);

	const handleChange = (e: any) => {
		const value = e.target.checked;
		if (value) store.addCategories(category);
		else store.removeCategory(category);
	};

	return (
		<label className="category-card">
			<input
				name={category.name}
				type="checkbox"
				onChange={handleChange}
			/>
			<div className="card-icon">
				<span className="check">{checkIcon}</span>
				<img src={category.thumbnail} alt={category.name} />
			</div>
			<div className="card-name uk-text-center">
				<p>{category.name}</p>
			</div>
		</label>
	);
};
