import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import laundryStore from "../../stores/LaundryStore";

export const LaundryCleaningCard = () => {
  const checkIcon = <FontAwesomeIcon icon={faCheck} />;

  const setModalName = () => {
    const store = laundryStore;
    store.setModalName("Clothing Care");
  };

  return (
    <div className="laundry-card uk-card">
      <ul className="content">
        <li>{checkIcon} 10 kg Load</li>
        <li>{checkIcon} Full Wash & Iron</li>
        <li>{checkIcon} Free Pick Up/Delivery</li>
      </ul>

      <div className="action">
        <a href="/#">
          N$<span>499</span>,00 <br />
          per month
        </a>
      </div>
      <button
        className="bookBtn uk-button uk-button-primary"
        uk-toggle="target: #laundry-modal"
        onClick={setModalName}
      >
        Book
      </button>
    </div>
  );
};
