import React from "react";

export const SingleProduct = () => {
	return (
		<>
			<div className="single-product uk-child-width-1-2@s" uk-grid="true">
				<div className="product-image-column">
					<div className="product-image uk-card uk-card-body">
						<div className="uk-card-badge uk-label">New</div>
						<img
							src="https://images-na.ssl-images-amazon.com/images/I/61nZv3qaUjL._SL1287_.jpg"
							alt=""
						/>
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details uk-card uk-card-body">
						<h3 className="uk-card-title">1L hand gel</h3>
						<p className="price">N$ 340.00</p>
						<p className="description">
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
							porro.
						</p>
						<div className="uk-margin">
							<input
								className="uk-input uk-form-width-medium"
								type="number"
								min={1}
								defaultValue={1}
							/>
						</div>

						<button className="uk-button uk-button-primary">Add to cart</button>
					</div>
				</div>
			</div>
		</>
	);
};
