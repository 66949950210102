import React, { useEffect, useState } from "react";
import { db } from "../../services/firebase";
import maintenanceStore from "../../stores/MaintenanceStore";
import { BookingModal } from "../modal/BookingModal";
import { MaintenanceServiceCard } from "./MaintenanceServiceCard";

export const MaintenanceServices = () => {
  // Firestore collection
  const collectionName = "maintenanceServices";
  // Store data
  const [maintenanceServices, setMaintenanceServices] = useState([]);
  //   Store
  const store = maintenanceStore;

  // On component mount retrieve data
  useEffect(() => {
    getMaintenanceServices();
    return () => {};
  }, []);

  const getMaintenanceServices = async () => {
    const $db = await db.collection(collectionName);
    return $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      setMaintenanceServices(docs);
    });
  };

  const displayMaintenanceServices = maintenanceServices.map((service: any) => (
    <div key={service.id}>
      <MaintenanceServiceCard item={service} />
    </div>
  ));

  return (
    <>
      <div className="other-services">
        <div className="uk-section uk-section-default">
          <div className="uk-container">
            <div
              className="uk-grid uk-child-width-1-2@s uk-child-width-1-3@m"
              uk-grid="true"
            >
              {displayMaintenanceServices}
            </div>
          </div>
        </div>
      </div>
      <BookingModal store={store} />
    </>
  );
};
