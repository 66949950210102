import React from "react";
import { useEffect } from "react";
import { useQuill } from "react-quilljs";

export const BlogView = () => {
  const { quill, quillRef } = useQuill();

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(
        "<h1>React Hook for Quill!</h1><p>Ola</p>"
      );
    }
  }, [quill]);

  console.log(quill); // undefined > Quill Object
  console.log(quillRef); // { current: undefined } > { current: Quill Editor Referenc
  return (
    <div style={{ width: 500, height: 300 }}>
      <div ref={quillRef} />
    </div>
  );
};
