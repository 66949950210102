import {
  faBriefcase,
  faReceipt,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { HelpCategoryItem } from "./HelpCategoryItem";

export const HelpContent = () => {
  const receiptIcon = <FontAwesomeIcon icon={faReceipt} />;
  const shieldIcon = <FontAwesomeIcon icon={faShieldAlt} />;
  const briefcaseIcon = <FontAwesomeIcon icon={faBriefcase} />;

  const generalCategory = {
    icon: receiptIcon,
    title: "General",
    description:
      "Harpic Flushmatic Aquamarine Toilet Cleaning Cistern Block is a drop-in tank tablet which can be used for any accessible cistern. Harpic Flushmatic toilet cleaner works from inside the cistern....",
  };
  const trustAndSafetyCategory = {
    icon: receiptIcon,
    title: "Trust & Safety",
    description:
      "Harpic Flushmatic Aquamarine Toilet Cleaning Cistern Block is a drop-in tank tablet which can be used for any accessible cistern. Harpic Flushmatic toilet cleaner works from inside the cistern....",
  };

  return (
    <div>
      <div className="help-content uk-section">
        <div className="uk-container">
          <h3 className="uk-text-center">Frequently Asked Questions</h3>
          <ul uk-accordion="true">
            {/* General Categories */}
            <li className="uk-margin">
              <a className="uk-accordion-title title" href="/#">
                General
              </a>
              <div className="uk-accordion-content uk-padding-small">
                <div className="uk-container">
                  <ul
                    className="uk-list uk-list-disc uk-list-primary"
                    uk-accordion="true"
                  >
                    <li className="uk-margin">
                      <a className="uk-accordion-title" href="/#">
                        Who do I contact if I have any issues or questions?
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          You can contact the Customer Care Team at{" "}
                          <a href="tel:+264 85 295 9595">+264 85 295 9595</a> or
                          email us at{" "}
                          <a href="mailto:info@koshaservices.com">
                            info@koshaservices.com
                          </a>
                          . Someone will respond to you immediately.
                        </p>
                      </div>
                    </li>
                    <li className="uk-margin">
                      <a className="uk-accordion-title" href="/#">
                        When are you open?
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          We are open <br />
                          Mon - Fri 8am - 5pm. <br />
                          Sat 9am - 3pm
                        </p>
                      </div>
                    </li>
                    <li className="uk-margin">
                      <a className="uk-accordion-title" href="/#">
                        What if I'm not there to let the cleaning person in?
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          That's okay! Just leave us detailed entry instructions
                          when you schedule your appointment online so we know
                          how to gain access to your home. Your home will be
                          locked when the Pro leaves.
                        </p>
                      </div>
                    </li>
                    <li className="uk-margin">
                      <a className="uk-accordion-title" href="/#">
                        Do I have to be home while you clean?
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          You do not have to be home as long as your Pro has
                          proper directions for entering and exiting your home.
                          Please input these instructions when booking your
                          appointment.
                        </p>
                      </div>
                    </li>
                    <li className="uk-margin">
                      <a className="uk-accordion-title" href="/#">
                        Will the same Pro show up for all my appointments?
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          If you're happy with your Pro you had, we're happy to
                          send them to all of your appointments. You just have
                          to contact us to let us know and we'll make it happen!
                        </p>
                      </div>
                    </li>
                    <li className="uk-margin">
                      <a className="uk-accordion-title" href="/#">
                        Can I book a cleaning over the phone?
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          Yes, absolutely! Give us a call at +264 85 295 9595
                          and we will set you up with a great cleaning
                          immediately.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            {/* Safety and Security */}
            <li className="uk-margin">
              <a className="uk-accordion-title title" href="/#">
                Trust & Security
              </a>
              <div className="uk-accordion-content uk-padding-small">
                <div className="uk-container">
                  <ul
                    className="uk-list uk-list-disc uk-list-primary"
                    uk-accordion="true"
                  >
                    <li className="uk-margin">
                      <a className="uk-accordion-title" href="/#">
                        Can I trust Kosha Pros?
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          Absolutely. All of Kosha Cleaning Professionals are
                          highly trained, background and reference checked.
                        </p>
                      </div>
                    </li>
                    <li className="uk-margin">
                      <a className="uk-accordion-title" href="/#">
                        How is Kosha handling COVID-19?
                      </a>
                      <div className="uk-accordion-content">
                        <p>
                          At Kosha, our top priority is the safety of our
                          cleaners and customers. These include:
                        </p>
                        <ol className="uk-list uk-list-decimal">
                          <li>
                            Washing hands frequently and vigorously for 20
                            seconds to prevent the spread of germs
                          </li>
                          <li>Avoiding touching of the eyes, nose and mouth</li>
                          <li>
                            Cleaning and disinfecting frequently touched objects
                            and surfaces using a regular household cleaning
                            spray or wipe
                          </li>
                          <li>Social distancing</li>
                        </ol>
                        <div className="uk-margin"></div>
                        <p>
                          We also ask that all customers proactively follow the
                          Covid guidelines to reduce the spread of the
                          Coronavirus during your bookings. Other
                          recommendations for customers include:
                        </p>
                        <ol className="uk-list uk-list-decimal">
                          <li>
                            Stay in a room separate from the one your Pro is
                            cleaning. This will (1) allow the Pro to clean
                            thoroughly and without interruption and (2) maintain
                            the 6-foot person-to-person distance currently
                            recommended.
                          </li>
                          <li>
                            Cancel or reschedule bookings if you feel sick or
                            are concerned about the wellbeing of those living in
                            your home or your cleaner.
                          </li>
                        </ol>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
