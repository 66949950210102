import React, { ChangeEvent } from "react";
import CleaningStore from "../../stores/CleaningStore";

export const BookingRegularity = () => {
  // Store
  const store = CleaningStore;
  // const yyyy = new Intl.DateTimeFormat("en", { year: "numeric" }).format(Date.now());
  // const mm = new Intl.DateTimeFormat("en", { month: "numeric" }).format(Date.now());
  // const dd = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(Date.now());
  // const date = `${mm}-${dd}-${yyyy}`;
  // console.log('Date: ', date);

  // const handleRegularityChange = (e: any) => {
  //   const isRegular = e.target.checked;
  //   store.setIsRegular(isRegular);
  // };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    console.log("Date: ", Date.parse(date));

    store.setRegularityDate(Date.parse(date));
  };

  // const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const time = e.target.value;
  //   console.log("Time: ", time);

  //   // store.setRegularityTime(Number(time));
  // };

  return (
    <div className="booking-regularity uk-margin">
      <h3 className="heading">
        When would you like us to come? <span></span>
      </h3>
      <div className="uk-grid-small" uk-grid="true">
        {/* <div className="regularity uk-width-1-3@m">
          <p className="promo uk-form-label">Save up to 10% on regular</p>
          <label className="uk-button-default">
            <input
              type="checkbox"
              name="regularity"
              onChange={handleRegularityChange}
              required
            />
            <span className="one-time">One time</span>
            <span className="regular">Regular</span>
          </label>
        </div> */}
        <div className="date uk-width-1-3@m">
          <label className="uk-form-label" htmlFor="booking-date">
            Date
          </label>
          <input
            className="uk-input"
            type="datetime-local"
            name="booking-date"
            onChange={handleDateChange}
            required
          />
        </div>
        {/* <div className="time uk-width-1-3@m">
          <label className="uk-form-label" htmlFor="booking-time">
            Time
          </label>
          <input
            className="uk-input"
            type="time"
            name="booking-time"
            onChange={handleTimeChange}
            required
          />
        </div> */}
      </div>
    </div>
  );
};
