import React, { ChangeEvent, useState } from "react";
import { Agent } from "../HomeSpace";

interface Props {
  agent: Agent;
}

interface viewPlaceForm {
  name: string;
  cellphone: string;
  email: string;
  viewPlaceOn: string;
}

const BookForViewing = (props: Props) => {
  // Props
  const { agent } = props;
  const { name } = agent;

  // Component properties
  const defaultFormValue: viewPlaceForm = {
    name: "",
    cellphone: "",
    email: "",
    viewPlaceOn: new Date(Date.now()).toUTCString(),
  };
  const [form, setForm] = useState<viewPlaceForm>(defaultFormValue);

  const onSubmit = (e: any) => {
    e.preventDefault();
    setForm(defaultFormValue);
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    setForm({ ...form, viewPlaceOn: date });
  };

  return (
    <form className="uk-form-stacked" onSubmit={onSubmit}>
      <legend className="uk-legend">Book for Viewing</legend>
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="customer-name">
          Full name
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            id="customer-name"
            type="text"
            placeholder="Full name"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="customer-phone">
          Phone
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            id="customer-phone"
            type="text"
            placeholder="Phone number"
            value={form.cellphone}
            onChange={(e) => setForm({ ...form, cellphone: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="customer-email">
          Email
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            id="customer-email"
            type="email"
            placeholder="Email"
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="uk-margin radio-inputs">
        <div className="uk-form-label">I want to view the place on:</div>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            type="datetime-local"
            name="booking-date"
            value={form.viewPlaceOn}
            onChange={handleDateChange}
            required
          />
        </div>
      </div>
      <button className="uk-button uk-button-default" type="submit">
        Book
      </button>
    </form>
  );
};

export default BookForViewing;
