import React from "react";
import { PageFooter } from "../components/footer/PageFooter";
import { InternetContent } from "../components/internet/InternetContent";
import { PageHeader } from "../components/shared/PageHeader";

export const InternetPage = () => {
  // Content of the header
  const headerContent = {
    bgImg:
      "https://images.pexels.com/photos/533446/pexels-photo-533446.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    title: "Internet Packages",
  };

  return (
    <>
      {/* Page header */}
      <PageHeader content={headerContent} />

      <div className="internet uk-section uk-section-default">
        <div className="uk-container">
          <InternetContent />
        </div>
      </div>

      {/* Page footer */}
      <PageFooter />
    </>
  );
};
