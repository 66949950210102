import React from "react";
import { BookingRegularity } from "./BookingRegularity";
import { OutdoorExras } from "./OutdoorsExtras";

export const Outdoors = () => {
  return (
    <div className="outdoors">
      {/* Ourdoors */}
      <OutdoorExras />
      {/* Regularity */}
      <BookingRegularity />
    </div>
  );
};
