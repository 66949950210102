import React from "react";

export const BlogCard = (props: any) => {
  const blog = props.item;
  const { thumbnail, date, title, summary } = blog;

  return (
    <div className="blog-card uk-card uk-card-default uk-card-small uk-card-hover">
      <div className="thumbnail uk-card-media-top">
        <img src={thumbnail} alt="" />
      </div>
      <div className="content uk-card-body">
        <p className="date">{date}</p>
        <h5 className="title">{title}</h5>
        <p className="summary">{summary}</p>
        <a className="link" href="/#">
          Read more
        </a>
      </div>
    </div>
  );
};
