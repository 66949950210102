import React from "react";

export const BlogFinder = () => {
  return (
    <div className="blog-finder">
      <form>
        <input className="search uk-input" type="text" placeholder="Search" />
        <select className="filter uk-select" defaultValue={1}>
          <option value={1} disabled>
            Sort
          </option>

          <option>Latest</option>
          <option>Oldest</option>
          <option>Favourite</option>
        </select>
      </form>
    </div>
  );
};
