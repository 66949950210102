import { makeAutoObservable, autorun, toJS } from "mobx";

class InternetStore {
  modalName: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  //   setModal name
  setModalName = (name: string) => {
    this.modalName = name;
  };
  //   getModal name
  get getModalName() {
    return this.modalName;
  }
}

const internetStore = new InternetStore();

autorun(() => {});

export default internetStore;
