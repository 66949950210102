import React from "react";
import { Link } from "react-router-dom";

export const ServiceLinks = () => {
  return (
    <>
      <li>
        <Link to="/cleaning">Home & Office Care</Link>
      </li>
      <li>
        <Link to="/laundry">Clothing Care</Link>
      </li>
      <li>
        <Link to="/maintenance">Home Maintenance</Link>
      </li>
      <li>
        <Link to="/internet">Internet Connectivity</Link>
      </li>
      <li>
        <Link to="/shop">Essential Goods</Link>
      </li>
    </>
  );
};
