import { makeObservable, observable } from "mobx";
import { CleaningCategory } from "../interfaces/CleaningInterfaces";

export default class MobxCategoryItem {
	// Categories observable
	id: string | number;
  name: string;
  count: number;

	constructor(category: CleaningCategory) {
		makeObservable(this, {
			name: observable,
			id: observable,
    });
    
		this.id = category.id;
    this.name = category.name;
    this.count = category.count ? category.count : 1;
	}
}
