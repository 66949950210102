import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
// import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

export const SocialLinks = () => {
  // const openIcon = <FontAwesomeIcon icon={faCaretUp} />;
  // const closeIcon = <FontAwesomeIcon icon={faCaretDown} />;
  const facebookIcon = <FontAwesomeIcon icon={faFacebook} />;
  const instagramIcon = <FontAwesomeIcon icon={faInstagram} />;
  const twitterIcon = <FontAwesomeIcon icon={faTwitter} />;
  const whatsappIcon = <FontAwesomeIcon icon={faWhatsapp} />;

  return (
    <>
      <div className="social-links">
        {/* <button className="uk-button" onClick={() => setClose(!isOpen)}>
          {isOpen ? openIcon : closeIcon}
        </button> */}
        <ul className="list">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/koshacleaningservices%20"
            >
              {facebookIcon}
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/kosha_cleaning_services/"
            >
              {instagramIcon}
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/KoshaCleaning"
            >
              {twitterIcon}
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=264813540251&text=I%27m%20inquiring%20about%20Kosha%20Cleaning%20Services"
            >
              {whatsappIcon}
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
