import React from "react";
import { InfoCard } from "./InfoCard";

export const SectionCompanyCommitment = () => {
	return (
		<>
			<div className="uk-section uk-section-secondary">
				<div className="uk-container">
					<div
						uk-height-match="target: > div > .uk-card"
						className="uk-grid uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-3@m"
						uk-grid="true"
					>
						<InfoCard />
					</div>
				</div>
			</div>
		</>
	);
};
