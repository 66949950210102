import React from "react";
import { BlogCard } from "./BlogCard";
import { BlogView } from "./BlogView";

export const BlogContent = () => {
  const team = [
    {
      thumbnail:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Privacy and Security",
      summary:
        "Harpic Flushmatic Aquamarine Toilet Cleaning Cistern Block is a drop-in tank tablet which can be awesome",
      date: "01 March 2019",
    },
    {
      thumbnail:
        "https://images.pexels.com/photos/2829581/pexels-photo-2829581.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Services",
      summary:
        "Harpic Flushmatic Aquamarine Toilet Cleaning Cistern Block is a drop-in tank tablet which can be awesome",
      date: "01 March 2019",
    },
    {
      thumbnail:
        "https://images.pexels.com/photos/3067694/pexels-photo-3067694.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Privacy and Security",
      summary:
        "Harpic Flushmatic Aquamarine Toilet Cleaning Cistern Block is a drop-in tank tablet which can be awesome",
      date: "01 March 2019",
    },
    {
      thumbnail:
        "https://images.pexels.com/photos/2403054/pexels-photo-2403054.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Services",
      summary:
        "Harpic Flushmatic Aquamarine Toilet Cleaning Cistern Block is a drop-in tank tablet which can be awesome",
      date: "01 March 2019",
    },
    {
      thumbnail:
        "https://images.pexels.com/photos/3418750/pexels-photo-3418750.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "How to start a business?",
      summary:
        "Harpic Flushmatic Aquamarine Toilet Cleaning Cistern Block is a drop-in tank tablet which can be awesome",
      date: "01 March 2019",
    },
  ];

  const displayBlog = team.map((member, key) => (
    <div className="blog-item" key={key}>
      <BlogCard item={member} />
    </div>
  ));

  return (
    <>
      <div
        className="uk-grid uk-child-width-1-2@s uk-child-width-1-3@m"
        uk-grid="true"
      >
        {displayBlog}
      </div>
      <BlogView />
    </>
  );
};
