import React from "react";
import { ProductReviews } from "../components/shop/ProductReviews";
import { SingleProduct } from "../components/shop/SingleProduct";

export const SingleProductPage = () => {
	return (
		<div className="uk-section">
			<div className="uk-container">
				<SingleProduct />
				<ProductReviews />
			</div>
		</div>
	);
};
