import React from "react";
import { CartItem } from "./CartItem";

export const Cart = () => {
	const cart = [
		{
			thumbnail:
				"https://images-na.ssl-images-amazon.com/images/I/61nZv3qaUjL._SL1287_.jpg",
			name: "1L hand gel",
			price: "N$234.00",
			description:
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita eaplaceat voluptatibus exercitationem consequuntur hic et",
		},
		{
			thumbnail:
				"https://cdn.wesmoindustries.com/assets/img/products/bathroom.jpg",
			name: "Bathroom kit",
			price: "N$34.00",
			description: "Expedita eaplaceat description more descrtiopn is here",
		},
		{
			thumbnail:
				"https://fastnfirstdelivery.com/wp-content/uploads/2020/06/bloo-toilet-cleaning-gel-500ml-9500ugx.jpg",
			name: "500ml Toilet cleaning gel, Sepal perfume",
			price: "N$94.00",
			description: "Voluptatibus exercitationem consequuntur",
		},
	];

	const renderCartList = cart.map((item, key) => (
		<div
			key={key}
			className="uk-card uk-card-default uk-card-small uk-card-body"
		>
			<CartItem item={item} />
		</div>
	));
	return (
		<>
			<div
				className="uk-background-cover uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
				style={{backgroundImage: 'url(http://localhost:3000/images/bg.jpg)'}}
			>
				<h3 className="uk-h4">My Cart</h3>
			</div>
			<div className="uk-section">
				<div className="uk-container">
					<div className="uk-grid" uk-grid="true">
						<div className="uk-width-expand@s">
							<div
								className="uk-grid-column-small uk-grid-row-medium"
								uk-grid="true"
							>
								{renderCartList}
							</div>
						</div>
						<div className="uk-width-1-3@s">
							<div className="uk-card uk-card-primary uk-card-body">
								<h3 className="uk-card-title">Continue to Checkout</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
