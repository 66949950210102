import { makeAutoObservable, autorun, toJS } from "mobx";

class SearchInputStore {
  private location: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  //   Location
  setLocation = (location: string) => {
    this.location = location;
  };
  //   Location
  get getLocation() {
    return this.location;
  }
}

const MobxSearchInputStore = new SearchInputStore();

autorun(() => {});

export default MobxSearchInputStore;
