import React from "react";

import { BookingForm } from "./BookingForm";
import { BookingHistory } from "./BookingHistory";
import { BookingSummary } from "./BookingSummary";

export const Cleaning = () => {
  return (
    // Make bookings page

    <div className="uk-grid" uk-grid="true">
      <div className="uk-width-expand@m">
        {/* Booking Form */}
        <BookingForm />
      </div>
      <div className="position-relative uk-width-1-3@m">
        {/* Booking History */}
        <BookingHistory />
        {/* Booking Summary */}
        <BookingSummary />
      </div>
    </div>
  );
};
