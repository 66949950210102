import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { CardCorporateLogo } from "./CardCorporateLogo";
import { db } from "../../services/firebase";

export const SectionCorporateClients = () => {
  // Firestore collection
  const collectionName = "corporateClients";
  // Store data
  const [clients, setClients] = useState([]);
  // Font awesome icons, import
  const leftIcon = <FontAwesomeIcon icon={faChevronLeft} />;
  const rightIcon = <FontAwesomeIcon icon={faChevronRight} />;

  useEffect(() => {
    getClients();
    return () => {};
  }, []);

  const getClients = async () => {
    const $db = await db.collection(collectionName);
    return $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      setClients(docs);
    });
  };

  const displayReviews = clients.map((company, key) => (
    <li className="company-item" key={key}>
      <CardCorporateLogo item={company} />
    </li>
  ));

  return (
    <>
      <div className="corporate-clients">
        <div className="uk-section uk-section-default">
          <div className="uk-position-relative uk-container">
            <div className="uk-position-relative">
              <h3 className="section-heading uk-text-center">
                Corporate Clients
              </h3>
              <div className="uk-slider-container-offset" uk-slider="true">
                <div
                  className="uk-position-relative uk-visible-toggle uk-light"
                  tabIndex={-1}
                >
                  <ul className="uk-slider-items uk-child-width-1-3@s uk-child-width-1-5@m uk-grid">
                    {displayReviews}
                  </ul>
                </div>
                <a
                  className="slider-controls previous"
                  href="/#"
                  uk-slider-item="previous"
                >
                  {leftIcon}
                </a>
                <a
                  className="slider-controls next"
                  href="/#"
                  uk-slider-item="next"
                >
                  {rightIcon}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
