import React from "react";
import MobxCategoryItem from "../../stores/MobxCategoryItem";
import CleaningStore from "../../stores/CleaningStore";

export const QuantityInputField = (props: any) => {
	const { category, index } = props;

	// Store
	const store = CleaningStore;

	const handleChange = (e: any) => {
		const count: number = Number(e.target.value);
		const $category: MobxCategoryItem = {
			...category,
			count,
		};
		const categoryCount = new MobxCategoryItem($category);
		store.updateCategoryCount(categoryCount, index);
	};

	return (
		<div className="quantity-input-item uk-margin">
			<label className="uk-form-label" htmlFor={category.name}>
				Number of {category.name}s
			</label>
			<div className="uk-form-controls">
				<input
					className="uk-input"
					name={category.name}
					type="number"
          min={1}
          defaultValue={1}
					onChange={(e) => handleChange(e)}
				/>
			</div>
		</div>
	);
};
