import React from "react";

export const CardReview = (props: any) => {
  const review = props.item;
  const { profilePicture, name, comment } = review;
  return (
    <div className="uk-card uk-card-default uk-card-hover uk-text-center">
      <div className="uk-card-media-top">
        <img src={profilePicture} alt="" />
      </div>
      <div className="uk-card-body">
        <h3 className="uk-card-title">{name}</h3>
        <p>"{comment}"</p>
      </div>
    </div>
  );
};
