import React, { useState } from "react";
import { db } from "../../services/firebase";
import MobxHomeSpaceStore from "../../stores/HomeSpaceStore";
import { Agent, Listing } from "./HomeSpace";

interface Props {
  listing: Listing;
}

export const PlaceCard = (props: any) => {
  const [store] = useState(() => MobxHomeSpaceStore);
  const { listing } = props;
  const {
    agent: agentID,
    name,
    location,
    price,
    baths,
    bedrooms,
    area,
    thumbnails,
  } = listing;

  const getAgent = async () => {
    if (agentID) {
      const $doc = await db
        .collection(store.firebaseAgentsCollection)
        .doc(agentID);
      return $doc
        .get()
        .then((doc) => {
          if (doc.exists) {
            const $doc: Agent = {
              id: doc.id,
              name: "",
              cellphone: "",
              email: "",
              profile_pic: "",
              ...doc.data(),
            };
            store.setAgent($doc);
          } else {
            // doc.data() will be undefined in this case
            // console.log("No such document!");
          }
        })
        .catch((error) => {
          // console.log("Error getting document:", error);
        });
    } else {
      // console.log("Agent iD null", agentID);
    }
  };

  const viewPlace = () => {
    // store.showPropertyModal();
    getAgent();
    store.setListing(listing);
  };

  return (
    <div>
      <div
        className="place-card uk-card uk-card-default uk-card-small uk-card-hover uk-grid-collapse uk-child-width-1-2@s uk-margin"
        uk-grid=""
      >
        <div className="media uk-card-media-left uk-cover-container">
          <div
            className="slider uk-position-relative uk-visible-toggle uk-light"
            tabIndex={-1}
            uk-slideshow=""
          >
            <ul className="uk-slideshow-items">
              {thumbnails &&
                thumbnails.map((thumbnail: string, index: number) => (
                  <li key={index}>
                    <img
                      data-src={thumbnail}
                      alt=""
                      uk-cover=""
                      uk-img="target: !.uk-slideshow-items"
                    />
                  </li>
                ))}
            </ul>

            <a
              className="uk-position-center-left uk-position-small uk-hidden-hover"
              href="/#"
              uk-slideshow-item="previous"
            >
              <span uk-icon="icon: chevron-left"></span>
            </a>
            <a
              className="uk-position-center-right uk-position-small uk-hidden-hover"
              href="/#"
              uk-slideshow-item="next"
            >
              <span uk-icon="icon: chevron-right"></span>
            </a>
          </div>
          <div className="uk-card-badge uk-label">Rent</div>
        </div>
        <div>
          <div className="body uk-card-body">
            <a
              className="uk-button-text "
              href="#view-property"
              uk-toggle=""
              onClick={viewPlace}
            >
              <h3 className="address uk-card-title text-left">
                {name + ", " + location}
              </h3>
            </a>
            <h3 className="price">N${price}</h3>
            <div className="options">
              {bedrooms && (
                <p className="beds">
                  <span>{bedrooms}</span>
                  <span className="label">Beds</span>
                </p>
              )}
              {baths && (
                <p className="baths">
                  <span>{baths}</span>
                  <span className="label">Baths</span>
                </p>
              )}
              {area && (
                <p className="area">
                  <span>{area}</span>
                  <span className="label">Sq. Ft.</span>
                </p>
              )}
            </div>
          </div>
          <div className="footer uk-card-footer">
            <a
              href="#view-property"
              uk-toggle=""
              onClick={viewPlace}
              className="uk-link"
            >
              View Place
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
