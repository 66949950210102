import React from "react";
import { PageFooter } from "../components/footer/PageFooter";
import { Maintenance } from "../components/manintenance/Maintenance";

export const MaintenancePage = () => {
  return (
    <>
      <Maintenance />

      {/* Page footer */}
      <PageFooter />
    </>
  );
};
