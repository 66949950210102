import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import MobxHomeSpaceStore from "../../../stores/HomeSpaceStore";
import ViewPlaceCardSlider from "./ViewPlaceCardSlider";
import WorkWithAgent from "./WorkWithAgent";

const ViewPropertyModal = observer(() => {
  const [store] = useState(() => MobxHomeSpaceStore);

  useEffect(() => {
    console.log("Mounting view modal");

    return () => {
      console.log("Unmounting view modal");
      // store.hidePropertyModal();
    };
  }, []);

  return (
    <div id="view-property" className="uk-modal-full" uk-modal="">
      <div className="uk-modal-dialog" uk-height-viewport="">
        <button
          className="uk-modal-close-full uk-close-large"
          type="button"
          // onClick={() => store.hidePropertyModal()}
          uk-close=""
        ></button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">{store.getListing.name}</h2>
        </div>

        <div className="uk-modal-body uk-padding-remove" uk-overflow-auto="">
          <div className="uk-section uk-section-muted">
            <div className="uk-container">
              <div className="uk-margin-large">
                <ViewPlaceCardSlider listing={store.getListing} />
              </div>
              {store.getAgent && (
                <div className="uk-margin-large">
                  <h4>Agent</h4>
                  <WorkWithAgent agent={store.getAgent} />
                </div>
              )}
              {/* Agent listings */}
              {/* <div className="uk-margin-large">
                <h4>Recommended Accommodation</h4>
                <ListingsSlider />
              </div> */}
            </div>
          </div>
        </div>

        {/*  */}
      </div>
    </div>
  );
});

export default ViewPropertyModal;
