import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { db } from "../../services/firebase";
import MobxHomeSpaceStore from "../../stores/HomeSpaceStore";
import MobxSearchInputStore from "../../stores/SearchInputStore";
import { AgentCard } from "./agent/AgentCard";
import DisplayBrowseContent from "./DisplayBrowseContent";
import DisplaySearchResults from "./DisplaySearchResults";
import NearByPlaceCard from "./near_by_places/NearByPlaceCard";
import { PlaceCard } from "./PlaceCard";
import ViewSearchResults from "./search_results/ViewSearchResults";
import UseAutocomplete from "./UseAutocomplete";
import ViewPropertyModal from "./view_property/ViewPropertyModal";

export interface Agent {
  id: string;
  name: string;
  cellphone: string;
  office?: string;
  email: string;
  profile_pic: string;
  occupation?: string;
  social_links?: {
    facebook?: string;
    instagram?: string;
    twitter?: string;
  };
  company?: {
    name: string;
    website?: string;
  };
}

export interface Listing {
  id: string;
  name: string;
  price: string;
  location: string;
  bedrooms: number;
  area: number;
  baths: number;
  agent: string;
  description?: string[];
  thumbnails: string[];
}

const HomeSpace = observer(() => {
  const [store] = useState(() => MobxHomeSpaceStore);
  const onSearch = MobxSearchInputStore.getLocation ? true : false;

  useEffect(() => {
    getAgents();
    getListings();
    return () => {
      MobxSearchInputStore.setLocation(""); // Clear the search input
    };
  }, []);

  const getAgents = async () => {
    if (store.getAgents.length) return;
    const $db = await db.collection(store.firebaseAgentsCollection);
    return $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      store.setAgents(docs);
    });
  };

  const getListings = async () => {
    if (store.getListings.length) return;
    const $db = await db.collection(store.firebaseListingsCollection);
    return $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      store.setListings(docs);
    });
  };

  const dispAgents = store.getAgents.map((agent) => (
    <div key={agent.id}>
      <AgentCard agent={agent} />
    </div>
  ));

  const dispListings = store.getListings.map((listing) => (
    <div key={listing.id}>
      <PlaceCard listing={listing} />
    </div>
  ));

  const dispNearByPlaces = store.getListings.map((listing) => (
    <div key={listing.id}>
      <NearByPlaceCard />
    </div>
  ));

  const displayPageContent = onSearch ? (
    <DisplaySearchResults />
  ) : (
    <DisplayBrowseContent
      DisplayAgents={dispAgents}
      DisplayListings={dispListings}
      DisplayNearByPlaces={dispNearByPlaces}
    />
  );

  return (
    <div>
      {/* Header - Includes the search */}
      <div
        // className="home-space-header uk-section uk-light uk-background-cover"
        className="home-space-header uk-section uk-background-cover"
        style={{
          backgroundImage:
            "url(https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
        }}
      >
        {/* Auto complete */}
        <div className="uk-container">
          {/* <div className="search-tabs">
            <ul uk-tab="">
              <li className="uk-active">
                <a href="/#">Rent</a>
              </li>
              <li>
                <a href="/#">Buy</a>
              </li>
              <li>
                <a href="/#">Agent</a>
              </li>
            </ul>
          </div> */}
          <div className="search uk-card">
            <UseAutocomplete />
          </div>
        </div>
      </div>

      <div>{displayPageContent}</div>

      {/* Modal */}
      <ViewPropertyModal />
    </div>
  );
});

export default HomeSpace;
