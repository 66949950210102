import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

export const LoginPage = () => {
  const facebookIcon = <FontAwesomeIcon icon={faFacebookF} />;
  const googleIcon = <FontAwesomeIcon icon={faGoogle} />;
	return (
		<div className="login-page">
			<div className="uk-section">
				<div className="uk-container uk-text-center">
					{/* Brand Logo */}
					<div className="brand uk-margin">
						<img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
					</div>

					{/* Login Card */}
					<div className="login-card uk-card uk-card-default">
						<div className="uk-card-header">
							<h3 className="uk-card-title uk-margin-remove-bottom">Sign In</h3>
						</div>
						<div className="uk-card-body">
							<form className="uk-form-stacked">
								<div className="uk-margin">
									<label className="uk-form-label uk-text-left" htmlFor="login-email">
										Email
									</label>
									<div className="uk-form-controls">
										<input className="uk-input" id="login-email" type="email" />
									</div>
								</div>

								<div className="uk-margin">
									<label className="uk-form-label uk-text-left" htmlFor="login-password">
										Password
									</label>
									<div className="uk-form-controls">
										<input
											className="uk-input"
											id="login-password"
											type="password"
										/>
									</div>
								</div>
								<div className="uk-margin uk-text-right">
									<a className="forgot-password" href="/#">
										Forgot password?
									</a>
								</div>
								<div className="uk-margin">
									<button className="sign-in uk-button uk-button-primary uk-width-1-1">
										Sign In
									</button>
								</div>
							</form>
						</div>
					</div>

					{/* Sign up */}
					<div className="uk-margin sign-up">
						<p className="uk-text-center">
							Don't have an account yet? <Link to="/sign-up">Sign up</Link>
						</p>
					</div>

          {/* Sign up */}
					<div className="social-login uk-margin">
						<button className="loginWFB uk-button uk-button-primary">{facebookIcon} with FACEBOOK</button>
            <button className="loginWG uk-button uk-button-danger">{googleIcon} with GOOGLE</button>
					</div>
				</div>
			</div>
		</div>
	);
};
