import React from "react";
import { PageFooter } from "../components/footer/PageFooter";
import ContactAgentModal from "../components/homespace/ContactAgentModal";
import HomeSpace from "../components/homespace/HomeSpace";

export const HomeSpacePage = () => {
  return (
    <>
      <HomeSpace />
      <PageFooter />

      {/* Modal */}
      <ContactAgentModal />
    </>
  );
};
