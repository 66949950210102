import React, { useState } from "react";
import { Agent } from "../HomeSpace";

interface Props {
  agent: Agent;
}

interface contactAgentForm {
  name: string;
  cellphone: string;
  email: string;
  interest: Interest;
}

enum Interest {
  RENT = "RENT",
  BUY = "BUY",
  SELL = "SELL",
}

const ContactAgentForm = (props: Props) => {
  // Props
  const { agent } = props;
  const { name } = agent;

  // Component properties
  const defaultFormValue: contactAgentForm = {
    name: "",
    cellphone: "",
    email: "",
    interest: Interest.RENT,
  };
  const [form, setForm] = useState<contactAgentForm>(defaultFormValue);

  const onSubmit = (e: any) => {
    e.preventDefault();
    console.log("Form => ", form);
    setForm(defaultFormValue);
  };

  return (
    <form className="uk-form-stacked" onSubmit={onSubmit}>
      <legend className="uk-legend">Work with {name}</legend>
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="customer-name">
          Full name
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            id="customer-name"
            type="text"
            placeholder="Full name"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="customer-phone">
          Phone
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            id="customer-phone"
            type="text"
            placeholder="Phone number"
            value={form.cellphone}
            onChange={(e) => setForm({ ...form, cellphone: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="customer-email-contact">
          Email
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            id="customer-email-contact"
            type="email"
            placeholder="Email"
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="uk-margin radio-inputs">
        <div className="uk-form-label">I am interested in:</div>
        <div className="uk-form-controls">
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="interest"
              value={Interest.RENT}
              checked={Interest.RENT === form.interest}
              onChange={(e) => setForm({ ...form, interest: Interest.RENT })}
            />
            Rent
          </label>
          <br />
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="interest"
              value={Interest.BUY}
              checked={Interest.BUY === form.interest}
              onChange={(e) => setForm({ ...form, interest: Interest.BUY })}
            />
            Buy
          </label>
          <br />
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="interest"
              value={Interest.SELL}
              checked={Interest.SELL === form.interest}
              onChange={(e) => setForm({ ...form, interest: Interest.SELL })}
            />
            Sell
          </label>
        </div>
      </div>
      <button className="uk-button uk-button-default" type="submit">
        Contact
      </button>
    </form>
  );
};

export default ContactAgentForm;
