import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Extra } from "../../interfaces/CleaningInterfaces";
import { BookingTaskItem } from "./BookingTaskItem";

export const BookingExtras = () => {
  const leftIcon = <FontAwesomeIcon icon={faChevronLeft} />;
  const rightIcon = <FontAwesomeIcon icon={faChevronRight} />;

  const extraList = [
    {
      id: 1,
      name: "Inside Oven",
      icon: process.env.PUBLIC_URL + "/icons/indoors/oven2.svg",
    },
    {
      id: 2,
      name: "Inside Wardrobe",
      icon: process.env.PUBLIC_URL + "/icons/indoors/closet2.svg",
    },
    {
      id: 3,
      name: "Internal Windows",
      icon: process.env.PUBLIC_URL + "/icons/outdoors/windows.svg",
    },
    {
      id: 4,
      name: "Inside Fridge",
      icon: process.env.PUBLIC_URL + "/icons/indoors/fridge.svg",
    },
    {
      id: 5,
      name: "Laundry & Ironing",
      icon: process.env.PUBLIC_URL + "/icons/indoors/ironing.svg",
    },
  ];

  const displayBookingExtras = extraList.map((extra: Extra) => (
    <div key={extra.id}>
      <BookingTaskItem extra={extra} />
    </div>
  ));

  return (
    <div className="booking-extras uk-margin">
      <h3 className="heading">
        Choose <span>Extras</span>
      </h3>

      <div className="uk-slider-container-offset" uk-slider="finite: true">
        <div
          className="uk-position-relative uk-visible-toggle uk-light"
          tabIndex={-1}
        >
          <ul className="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-6@l uk-grid-small">
            {displayBookingExtras}
          </ul>
        </div>
        <a
          className="slider-controls previous"
          href="/#"
          uk-slider-item="previous"
        >
          {leftIcon}
        </a>
        <a className="slider-controls next" href="/#" uk-slider-item="next">
          {rightIcon}
        </a>
      </div>
    </div>
  );
};
