import React from "react";

export const MaintenanceServicesDescription = () => {
  return (
    <div className="uk-section uk-padding-remove-top">
      <div className="uk-container">
        <div className="maintenance-services-description">
          <ul uk-accordion="collapsible: true">
            {/* Plumber */}
            <li className="uk-card uk-card-default  uk-card-small uk-card-body">
              <a className="uk-accordion-title" href="/#">
                Professional plumber
              </a>
              <div className="uk-accordion-content">
                <ul className="uk-list uk-list-hyphen">
                  <li>
                    When you have a plumbing issue that needs professional
                    attention, look no further than Kosha. No matter how skilled
                    you might be at home maintenance, some jobs should only ever
                    be tackled by a professional, and plumbing is one of them.
                    When you book plumbers through the Kosha platform, you'll be
                    booking a cheap but reliable plumbing professional who will
                    know just where to look to find the source of the problem
                    and who will have all the right tools and expertise to fix
                    it.
                  </li>
                </ul>
              </div>
            </li>

            {/* Handyman */}
            <li className="uk-card uk-card-default  uk-card-small uk-card-body">
              <a className="uk-accordion-title" href="/#">
                Handyman
              </a>
              <div className="uk-accordion-content">
                <ul className="uk-list uk-list-hyphen">
                  <li>
                    If you’re looking for the best handyman service to help you
                    out around the house, look no further than Kosha. We know
                    you’ve got a never-ending list of odd jobs that need doing
                    around your home. However, with the demands of work and
                    family, it’s so hard to find the time to tackle them all.
                  </li>
                  <li>
                    If the sight of a hammer or screwdriver fills you with
                    anxiety and you’d rather leave things to a professional, the
                    Kosha platform is ready to help.
                  </li>
                </ul>
              </div>
            </li>

            {/* Electrician */}
            <li className="uk-card uk-card-default  uk-card-small uk-card-body">
              <a className="uk-accordion-title" href="/#">
                Electrician
              </a>
              <div className="uk-accordion-content">
                <ul className="uk-list uk-list-hyphen">
                  <li>
                    Are you looking for the best electrician in your area? Kosha
                    has you covered. Some jobs should only be undertaken by a
                    professional, and we know you’d rather not fiddle with your
                    electrical wiring or outlets unless you know exactly what
                    you’re doing.
                  </li>
                  <li>
                    When you use the Kosha platform to book a professional
                    electrical contractor, you can take all your stress out of
                    the equation. Why run the risk of making an electrical
                    problem even more complicated when you can book a
                    professional electrician to take care of those faulty
                    outlets or run a wire to that new lighting fixture?
                  </li>
                </ul>
              </div>
            </li>

            {/* Painter */}
            <li className="uk-card uk-card-default  uk-card-small uk-card-body">
              <a className="uk-accordion-title" href="/#">
                Painter
              </a>
              <div className="uk-accordion-content">
                <ul className="uk-list uk-list-hyphen">
                  <li>
                    Whether you’re selling your home, just moved into a new
                    space, or it’s simply time to give your living room a
                    facelift, painting your home is an excellent way to make
                    maximum impact with minimal investment. However, home
                    painting is something that many people feel they can do
                    themselves – only to discover very quickly that they can’t!
                    Without the proper skills, experience, and equipment, home
                    painting can quickly become an expensive nightmare.
                  </li>
                </ul>
              </div>
            </li>

            {/* Carpenter */}
            <li className="uk-card uk-card-default  uk-card-small uk-card-body">
              <a className="uk-accordion-title" href="/#">
                Carpenter
              </a>
              <div className="uk-accordion-content">
                <ul className="uk-list uk-list-hyphen">
                  <li>
                    As the old saying goes, “owning a hammer doesn’t make you a
                    carpenter.” If you’re looking for carpentry services you can
                    trust, Kosha is your go-to source to get the job done right.
                  </li>
                  <li>
                    Whether you need a few cabinets repaired or a full built-in
                    shelving system designed, we’ll walk you through every step
                    of the process for a simple and seamless experience.
                  </li>
                </ul>
              </div>
            </li>

            {/* Air condition */}
            <li className="uk-card uk-card-default  uk-card-small uk-card-body">
              <a className="uk-accordion-title" href="/#">
                Air Conditioner Technician
              </a>
              <div className="uk-accordion-content">
                <ul className="uk-list uk-list-hyphen">
                  <li>
                    If you’re looking for air conditioning services, including
                    anything from general HVAC repair to complete installation,
                    we can help! If your HVAC system has been condemned by
                    another company, give us a call and let us give your home or
                    business a second opinion!
                  </li>
                  <li>
                    If you do decide to replace your HVAC system, we can help
                    you find an air conditioning system that is the right size
                    for your home. This includes a unit that is efficient, meets
                    your needs and has a strong warranty.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
