export const koshaTabContent = {
  title: "Home & Office Space Care",
  description: "Book your next clean with our trusted Hygiene Officers",
  bookingBtn: "Book Now",
  bookingRouteTo: "cleaning",
};
export const laundryTabContent = {
  title: "Clothing Care",
  description: "Get your laundry refreshed & feel confident",
  bookingBtn: "Book Now",
  bookingRouteTo: "laundry",
};
export const realEstateTabContent = {
  title: "Home Search",
  description: "Find a place to Rent, Buy listed by your favourite Agent",
  bookingBtn: "Search for spaces",
  bookingRouteTo: "homespace",
};
export const maintenanceTabContent = {
  title: "Home & Office Maintenance",
  description:
    "Book a professional plumber, handyman, electrician, painter, carpenter and civil contractor ",
  bookingBtn: "Requrest Quote",
  bookingRouteTo: "maintenance",
};

export const internetTabContent = {
  title: "Home & Office Internet Connectivity",
  description:
    "Get an affordable Plug & Play 4-G Wifi router powered by MTN Business",
  bookingBtn: "See Packages & Plans",
  bookingRouteTo: "internet",
};
