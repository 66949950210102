import React from "react";
import ContactAgentForm from "./ContactAgentForm";
import AgentInfoMini from "../agent/AgentInfoMini";
import BookForViewing from "./BookForViewing";
import { Agent } from "../HomeSpace";
import { useHistory } from "react-router";

declare const UIkit: any;

interface Props {
  agent: Agent;
}

const WorkWithAgent = (props: Props) => {
  const history = useHistory();

  const onBookForCleaning = (e: any) => {
    const modal = UIkit.modal("#view-property");
    if (modal) modal.hide();
    history.push("/cleaning");
  };

  return (
    <div className="uk-grid uk-child-width-1-4@">
      {/* Agent */}
      <div className="uk-width-1-4@s uk-margin">
        <AgentInfoMini agent={props.agent} />
        <div className="uk-margin uk-text-center">
          <button
            className="uk-button uk-button-primary"
            onClick={onBookForCleaning}
          >
            Get your place cleaned
          </button>
        </div>
      </div>
      <div className="uk-width-expand">
        <div className="uk-grid uk-child-width-expand@s uk-grid-match">
          {/* Contact */}
          <div>
            <div className="uk-card uk-card-default uk-card-body">
              <ContactAgentForm agent={props.agent} />
            </div>
          </div>
          {/* Book for viewing */}
          <div>
            <div className="uk-card uk-card-secondary uk-card-body">
              <BookForViewing agent={props.agent} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkWithAgent;
