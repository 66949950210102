import React from "react";
import FilterPlaces from "./FilterPlaces";
import SearchResultsContent from "./SearchResultsContent";

const ViewSearchResults = () => {
  return (
    <div className="view-search-results">
      <div className="uk-grid" uk-grid="">
        {/* <div className="uk-width-1-4@s">
          <FilterPlaces />
        </div> */}
        <div className="uk-width-expand@s">
          <SearchResultsContent />
        </div>
      </div>
    </div>
  );
};

export default ViewSearchResults;
