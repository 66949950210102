import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const CartItem = (props: any) => {
	const closeIcon = <FontAwesomeIcon icon={faTimes} />;
	const { thumbnail, name, description, price } = props.item;
	return (
		<div
			className="cart-item uk-grid-small"
			uk-grid="true"
			uk-height-match="target: > div > .match"
		>
			<div className="uk-width-1-5@s">
				<div className="match image">
					<img src={thumbnail} alt="" />
				</div>
			</div>
			<div className="uk-width-1-2@s">
				<div className="match">
					<h3>{name}</h3>
					<p className="description">{description}</p>
				</div>
			</div>
			<div className="uk-width-1-6@s">
				<div className="match v-h-center">
					<p className="price">{price}</p>
					<input className="uk-input" type="number" min={1} defaultValue="1" />
				</div>
			</div>
			<div className="uk-width-expand@s ">
				<div className="match close-btn-container">
					<button className="close-btn">{closeIcon}</button>
				</div>
			</div>
		</div>
	);
};
