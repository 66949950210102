import React, { useState } from "react";

export interface ProductCheckbox {
  name: string;
  isChecked: boolean;
}

export const TemporaryShopTabsContent = (props: any) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const {
    kitchenProducts,
    setKitchenProducts,
    bathroomProducts,
    setBathroomProducts,
    officeProducts,
    setOfficeProducts,
    generalProducts,
    setGeneralProducts,
  } = props;

  const handleProductsChange = (
    e: any,
    products: ProductCheckbox[],
    setter: any
  ) => {
    // Get name
    const name = e.target.name;
    // Get state
    const isChecked = e.target.checked;

    // Get products
    const $products = products;
    // Find the item that changed
    const index = $products.findIndex(
      (item: ProductCheckbox) => item.name === name
    );
    // Update the change
    $products[index].isChecked = isChecked;
    setter($products);
  };

  const dispKitchenCheckboxes = kitchenProducts.map((item: ProductCheckbox) => (
    <label key={item.name}>
      <input
        className="uk-checkbox"
        type="checkbox"
        name={item.name}
        defaultChecked={item.isChecked}
        onChange={(e) =>
          handleProductsChange(e, kitchenProducts, setKitchenProducts)
        }
      />
      {item.name}
    </label>
  ));

  const dispBathroomCheckboxes = bathroomProducts.map(
    (item: ProductCheckbox) => (
      <label key={item.name}>
        <input
          className="uk-checkbox"
          type="checkbox"
          name={item.name}
          defaultChecked={item.isChecked}
          onChange={(e) =>
            handleProductsChange(e, bathroomProducts, setBathroomProducts)
          }
        />
        {item.name}
      </label>
    )
  );

  const dispOfficeCheckboxes = officeProducts.map((item: ProductCheckbox) => (
    <label key={item.name}>
      <input
        className="uk-checkbox"
        type="checkbox"
        name={item.name}
        defaultChecked={item.isChecked}
        onChange={(e) =>
          handleProductsChange(e, officeProducts, setOfficeProducts)
        }
      />
      {item.name}
    </label>
  ));

  const dispGeneralCheckboxes = generalProducts.map((item: ProductCheckbox) => (
    <label key={item.name}>
      <input
        className="uk-checkbox"
        type="checkbox"
        name={item.name}
        defaultChecked={item.isChecked}
        onChange={(e) =>
          handleProductsChange(e, generalProducts, setGeneralProducts)
        }
      />
      {item.name}
    </label>
  ));

  const disp = () => {
    let content = <></>;
    switch (activeTabIndex) {
      case 1:
        content = <KitchenProduct />;
        break;
      case 2:
        content = <BathroomProducts />;
        break;
      case 3:
        content = <OfficeProducts />;
        break;
      case 4:
        content = <GeneralProducts />;
        break;
      default:
        content = <KitchenProduct />;
    }

    return content;
  };

  /* Kitchen products */
  const KitchenProduct = () => {
    return (
      <div className="uk-width-1-1">
        <h4 className="uk-heading">Kitchen Essential Products</h4>

        <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid uk-flex uk-flex-column">
          {dispKitchenCheckboxes}
        </div>
      </div>
    );
  };

  /* Bathroom products */
  const BathroomProducts = () => {
    return (
      <div className="uk-width-1-1">
        <h4 className="uk-heading">Bathroom Essential Products</h4>

        <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid uk-flex uk-flex-column">
          {dispBathroomCheckboxes}
        </div>
      </div>
    );
  };

  // Office products
  const OfficeProducts = () => {
    return (
      <div className="uk-width-1-1">
        <h4 className="uk-heading">Office Essential Products</h4>

        <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid uk-flex uk-flex-column">
          {dispOfficeCheckboxes}
        </div>
      </div>
    );
  };

  // General products
  const GeneralProducts = () => {
    return (
      <div className="uk-width-1-1">
        <h4 className="uk-heading">General Essential Products</h4>

        <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid uk-flex uk-flex-column">
          {dispGeneralCheckboxes}
        </div>
      </div>
    );
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <ul className="temporary-shop-category" uk-tab="">
        <li className="uk-active">
          <a href="#/" onClick={() => setActiveTabIndex(1)}>
            Kitchen{" "}
          </a>
        </li>
        <li>
          <a href="#/" onClick={() => setActiveTabIndex(2)}>
            Bathroom
          </a>
        </li>
        <li>
          <a href="#/" onClick={() => setActiveTabIndex(3)}>
            Office{" "}
          </a>
        </li>
        <li>
          <a href="#/" onClick={() => setActiveTabIndex(4)}>
            General
          </a>
        </li>
      </ul>
      {disp()}
    </div>
  );
};
