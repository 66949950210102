import React from "react";
import maintenanceStore from "../../stores/MaintenanceStore";

export const MaintenanceServiceCard = (props: any) => {
  const service = props.item;
  const { thumbnail, name } = service;

  const setModalName = () => {
    const store = maintenanceStore;
    store.setModalName(name);
  };

  return (
    <div className="maintenance-service-card uk-card uk-card-small uk-card-hover uk-text-center">
      <div className="uk-overlay uk-overlay-default uk-position-center">
        <div className="pos-relative">
          <p className="uk-link-heading uk-margin-remove">{name}</p>
        </div>
      </div>
      <div className="uk-card-media-top">
        <img src={thumbnail} alt={name} />
      </div>
      <div className="uk-card-footer">
        <button
          className="uk-button uk-button-default"
          uk-toggle="target: #booking-modal"
          onClick={setModalName}
        >
          Request Now
        </button>
      </div>
    </div>
  );
};
