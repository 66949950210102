import React from "react";

export const BookingTabItem = (props: any) => {
	const index = props.index;
	const tab = props.tab;
	const setActiveTab = props.setActiveTab;
	const activeIndex = props.activeIndex;

	const activeClass = index === activeIndex ? "uk-active" : "";
	return (
		<li className={activeClass}>
			<a href="/#" onClick={() => setActiveTab(index)}>
				{tab}
			</a>
		</li>
	);
};
