import React, { Fragment } from "react";

interface Props {
  DisplayListings: JSX.Element[];
  DisplayAgents: JSX.Element[];
  DisplayNearByPlaces: JSX.Element[];
}

const DisplayBrowseContent = (props: Props) => {
  const { DisplayAgents, DisplayListings, DisplayNearByPlaces } = props;
  return (
    <Fragment>
      {/* Places */}
      <div className="uk-section uk-section-muted">
        <div className="uk-container">
          <h4>Places</h4>
          <div
            className="uk-grid uk-grid-medium uk-child-width-1-1@s uk-child-width-1-2@m "
            uk-grid="true"
          >
            {DisplayListings}
          </div>
        </div>
      </div>

      {/* Agent */}
      <div className="uk-section uk-section-default">
        <div className="uk-container">
          <h4>Agents</h4>
          <div>
            <div
              className="uk-grid uk-grid-medium uk-child-width-1-2@s uk-child-width-1-4@m"
              uk-grid="true"
            >
              {DisplayAgents}
            </div>
          </div>
        </div>
      </div>

      <div className="uk-section uk-section-secondary">
        <div className="uk-container">
          <h4>Explore Near by Places</h4>
          <div>
            <div
              className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l"
              uk-grid=""
            >
              {DisplayNearByPlaces}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DisplayBrowseContent;
