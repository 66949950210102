/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ServiceLinks } from "./ServiceLinks";
import { CompanyLinks } from "./CompanyLinks";

export const TopNav = () => {
  const caretDownIcon = <FontAwesomeIcon icon={faCaretDown} />;
  const signInIcon = <FontAwesomeIcon icon={faSignInAlt} />;

  return (
    <>
      <div className="nav-container">
        <nav className="nav-bar uk-navbar-container" uk-navbar="true">
          <div className="uk-navbar-left">
            <button
              className="uk-navbar-toggle uk-hidden@s"
              uk-toggle="target: #offcanvas-nav-primary"
              uk-navbar-toggle-icon=""
            ></button>

            <Link className="uk-navbar-item uk-logo logo" to="/">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.png"}
                alt="Kosha"
              />
            </Link>

            <ul className="uk-navbar-nav uk-visible@s">
              <li className="uk-inline">
                <button className="round-btn" type="button">
                  Company
                  <span className="icon">{caretDownIcon}</span>
                </button>
                <div uk-dropdown="mode: hover; animation: uk-animation-slide-top-small; duration: 100">
                  <ul className="uk-nav uk-navbar-dropdown-nav">
                    <li className="uk-nav-header">Services & Products</li>
                    <ServiceLinks />
                    {/* <li className="uk-nav-divider"></li> */}
                    <li className="uk-nav-header">Company</li>
                    <CompanyLinks />
                  </ul>
                </div>
              </li>
            </ul>
          </div>

          {/* <div className="uk-navbar-right">
            <ul className="uk-navbar-nav">
              <li className="round-btn-container">
                <Link to="/login">
                  <span className="icon">{signInIcon}</span> Login
                </Link>
              </li>
            </ul>
          </div> */}
        </nav>
      </div>

      <div id="offcanvas-nav-primary" uk-offcanvas="overlay: true">
        <div className="uk-offcanvas-bar uk-flex uk-flex-column">
          <ul className="uk-nav uk-nav-primary uk-nav-left uk-margin-auto-vertical">
            <ServiceLinks />
            <li className="uk-nav-divider"></li>
            <CompanyLinks />
          </ul>
        </div>
      </div>
    </>
  );
};
