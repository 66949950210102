import React from "react";

export const CompanyStatements = () => {
  return (
    <div className="uk-section">
      <div className="uk-container">
        <ul uk-accordion="collapsible: true">
          {/* Our story */}
          <li className="uk-card uk-card-default  uk-card-small uk-card-body">
            <a className="uk-accordion-title" href="/#">
              Our Story
            </a>
            <div className="uk-accordion-content">
              <ol className="uk-list uk-list-hyphen">
                <li>
                  In 2018, Abisai, Levy, Halleluya, Alex and Vatuva, launched
                  this company at a time where the Namibian economy was in
                  prolonged recession or depression they called it. We did not
                  want to start a traditional cleaning company but build a brand
                  and a unique business, something new, something different. And
                  then Kosha was born, a Home & Office Space Care Company.
                  Founding a company is only step zero of a million steps of
                  building a company, Toini and Etuwete joined to lead our
                  branding and marketing and become the leading home & office
                  care brand that we are today.
                </li>
                <li>
                  Without our hygiene officers (Pros) we could not be reaching
                  5000+ bookings, our Pros helped us deliver better experiences
                  to 1000+ homes across our operating areas
                </li>
                <li>
                  In the early days of 2018, we cleaned homes ourselves to give
                  our Pros scale and embed our customer-centric culture.
                  Subsequently, in our first-year operations, we took 15 people
                  from the unemployment bucket and gave them a sense of
                  belonging and economic opportunity at Kosha while delighting
                  our customers and building families by buying them more time
                  to do important stuff.
                </li>
                <li>
                  There we created a Kosha for our customers, communities, and
                  certainly Africa.
                </li>
              </ol>
            </div>
          </li>

          {/* Our mission */}
          <li className="uk-card uk-card-default  uk-card-small uk-card-body">
            <a className="uk-accordion-title" href="/#">
              Our Mission and Vision
            </a>
            <div className="uk-accordion-content">
              <ul className="uk-list">
                <li>
                  <h4>Mission</h4>
                  <ul className="uk-list uk-list-hyphen">
                    <li>To make living convenient</li>
                  </ul>
                </li>
                <li>
                  <h4>Vision</h4>
                  <ul className="uk-list uk-list-hyphen">
                    <li>
                      To be a catalyst of community building by delivering
                      connected experiences
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Better Experiences</h4>
                  <ul className="uk-list uk-list-hyphen">
                    <li>
                      Thus going forward, we not just a cleaning company but a
                      company that is in the business of living and managing the
                      spaces that people live from home, offices to the
                      in-between through <strong>Better Experiences</strong>.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>

          {/* What we value */}
          <li className="uk-card uk-card-default  uk-card-small uk-card-body">
            <a className="uk-accordion-title" href="/#">
              What We Value
            </a>
            <div className="uk-accordion-content">
              <ul className="uk-list">
                <li>
                  <h4>Our People make Kosha</h4>
                  <ul className="uk-list uk-list-hyphen">
                    <li>
                      Kosha is built by our people who come from disadvantaged
                      backgrounds and want to improve their socio-economic
                      status. As a company, we are in pursuit of connections and
                      experiences while our Pros are in pursuit of a better
                      life. That forges a strong bond between our customers and
                      Pro, this bond is driven by sincere care for customers’
                      homes and care for their families. Thus for every dollar,
                      our customer pays, fifty cents go to our Pros and their
                      families.
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Our Customers make Kosha</h4>
                  <ul className="uk-list uk-list-hyphen">
                    <li>
                      We designed Kosha to be customer-facing and customer
                      first. This was purposeful and the right thing to do. Our
                      customers build Kosha through their dedicated ongoing
                      support, referrals, and desire to care better for the
                      spaces they live in. We give back to them through our
                      unconditional effort to deliver better experiences and
                      help them build their families in the most convenient way
                      possible.
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Our Partners make Kosha</h4>
                  <ul className="uk-list uk-list-hyphen">
                    <li>
                      At Kosha we believe competition destroys value and renders
                      no real change in society. Hence we love to partner, our
                      partners enable us to deliver on our pursuit for better
                      and connected experiences. We collaborate and cooperate
                      with enterprises such as MTN to deliver home connectivity
                      to Taxi Drivers delivering laundry to our customers. Thus
                      it is not our partner size or stature but shared-vision to
                      help us make Kosha while we help them build their business
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
