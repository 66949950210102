import React from "react";
import { BlogContent } from "../components/blog/BlogContent";
import { BlogFinder } from "../components/blog/BlogFinder";
import { PageFooter } from "../components/footer/PageFooter";
import { PageHeader } from "../components/shared/PageHeader";

export const BlogPage = () => {
  // Content of the header
  const headerContent = {
    bgImg:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    title: "Blogs",
  };

  return (
    <>
      {/* Page header */}
      <PageHeader content={headerContent} />

      <div className="blog uk-section uk-section-muted">
        <div className="uk-container">
          {/* Blog finder section: Search and filter section */}
          <BlogFinder />

          {/* Blog items */}
          <div className="uk-margin-large">
            <BlogContent />
          </div>
        </div>
      </div>

      {/* Page footer */}
      <PageFooter />
    </>
  );
};
