import React from "react";
import { MidNav } from "./MidNav";

export const Banner = () => {
  return (
    <div className="service-banner">
      <div
        className="banner-background uk-background-cover uk-panel"
        style={{
          backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/kosha-439e6.appspot.com/o/backgroundImages%2Fbg-4-min.jpg?alt=media&token=dfd2e72d-3290-4e82-9ff0-9c0edb95a051')`,
          //   '${
          //     process.env.PUBLIC_URL + "/images/bg-1.jpg"
          //   }'
        }}
      >
        <div className="uk-section">
          <div className="uk-container pos-relative">
            <MidNav />
          </div>
        </div>
      </div>
    </div>
  );
};
