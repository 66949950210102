import React, { useEffect, useState } from "react";
import { db } from "../../services/firebase";
import cleaningSubscriptionsStore from "../../stores/CleaningSubscriptionStore";
import { CleaningSubscriptionModal } from "../modal/CleaningSubscriptionModal";
import { SubscriptionCard } from "./SubscriptionCard";

export const BookingSubscriptions = () => {
  const collectionName = "cleaningSubscriptions";
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);
  const store = cleaningSubscriptionsStore;

  useEffect(() => {
    getCleaningSubscriptionsPackages();
    return () => {};
  }, []);

  const getCleaningSubscriptionsPackages = async () => {
    const $db = await db.collection(collectionName).orderBy("order");
    return $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      setSubscriptionPackages(docs);
    });
  };

  const displaySubscriptions = subscriptionPackages.map((pack, key) => (
    <div className="subscription-package" key={key}>
      <SubscriptionCard item={pack} />
    </div>
  ));

  return (
    <>
      <div className="booking-subscriptions uk-margin-large-top">
        <div
          className="uk-grid uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small"
          uk-grid="true"
        >
          {displaySubscriptions}
        </div>
      </div>
      <CleaningSubscriptionModal store={store} />
    </>
  );
};
