import React from "react";
import { Link } from "react-router-dom";

export const CompanyLinks = () => {
  return (
    <>
      <li>
        <Link to="/about">About Us</Link>
      </li>
      {/* <li>
              <Link to="/blogs">Our Blogs</Link>
            </li> */}
      <li>
        <Link to="/faq">FAQ</Link>
      </li>
      {/* <li>
              <Link to="/contact">Contact Us</Link>
            </li> */}
    </>
  );
};
