import React, { useEffect, useState } from "react";
import { db } from "../../services/firebase";
import internetStore from "../../stores/InternetStore";
import { InternetPackagesModal } from "../modal/InternetPackagesModal";
import { InternetCard } from "./InternetCard";

export const InternetContent = () => {
  const collectionName = "internetPackages";
  const [internetPackages, setInternetPackages] = useState([]);
  const store = internetStore;

  useEffect(() => {
    getInternetPackages();
    return () => {};
  }, []);

  const getInternetPackages = async () => {
    const $db = await db.collection(collectionName).orderBy("order");
    return $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      setInternetPackages(docs);
    });
  };

  const displayInternet = internetPackages.map((pack, key) => (
    <div className="internet-package" key={key}>
      <InternetCard item={pack} />
    </div>
  ));
  return (
    <div>
      <div
        className="uk-grid uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center"
        uk-grid="true"
      >
        {displayInternet}
      </div>
      <InternetPackagesModal store={store} />
    </div>
  );
};
