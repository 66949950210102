import React from "react";
import internetStore from "../../stores/InternetStore";

export const InternetCard = (props: any) => {
  const pack = props.item;
  const { title, description, price, bestValue } = pack;

  const dollars = Math.trunc(price);
  const cents = Math.trunc((price % dollars) * 100);

  const displayDescription = description.length ? (
    description.map((desc: any, key: any) => (
      <p className="description" key={key}>
        {desc}
      </p>
    ))
  ) : (
    <p></p>
  );

  const setModalName = () => {
    const store = internetStore;
    store.setModalName(title);
  };
  return (
    <div className="internet-card uk-card uk-card-default uk-text-center uk-card-hover">
      <div className="content uk-card-body">
        {bestValue && <p className="badge">Best Value</p>}
        <h5 className="title">{title}</h5>
        <p className="price">
          <span>N$</span> {dollars} <span>{cents}</span>
        </p>
        {displayDescription}
      </div>
      <div className="footer uk-card-footer">
        <button
          className="uk-button uk-button-primary"
          uk-toggle="target: #internet-modal"
          onClick={setModalName}
        >
          Choose Plan
        </button>
      </div>
    </div>
  );
};
