import React, { useState } from "react";
import { useHistory } from "react-router";
import MobxHomeSpaceStore from "../../../stores/HomeSpaceStore";
import { Agent } from "../HomeSpace";

declare const UIkit: any;

interface Props {
  agent: Agent;
}

const AgentInfoMini = (props: Props) => {
  const [store] = useState(() => MobxHomeSpaceStore);
  const history = useHistory();

  const { agent } = props;
  const { name, email, cellphone, profile_pic, social_links } = agent;

  const viewAgentListings = () => {
    store.setAgent(agent);
    const modal = UIkit.modal("#view-property");
    if (modal) modal.hide();
    history.push("/agents-listings/" + agent.id);
  };

  return (
    <div className="agent-info-mini uk-card uk-card-default uk-card-small">
      <div className="uk-card-media-top">
        <img src={profile_pic} alt="" />
      </div>
      <div className="uk-card-body">
        <h3 className="agent-name uk-card-title">{name}</h3>
        <p className="email">{email}</p>
        <p className="cellphone">{cellphone}</p>
      </div>
      <div className="uk-card-footer">
        <div className="links">
          {social_links?.facebook && (
            <a
              href={social_links?.facebook}
              target="_blank"
              rel="noreferrer"
              className="uk-icon-button uk-margin-small-right"
            >
              <span uk-icon="facebook" style={{ color: "#3b5998" }}></span>
            </a>
          )}
          {social_links?.twitter && (
            <a
              href={social_links?.twitter}
              target="_blank"
              rel="noreferrer"
              className="uk-icon-button uk-margin-small-right"
            >
              <span uk-icon="twitter" style={{ color: "#55acee" }}></span>
            </a>
          )}
          {social_links?.instagram && (
            <a
              href={social_links?.instagram}
              target="_blank"
              rel="noreferrer"
              className="uk-icon-button"
            >
              <span uk-icon="instagram" style={{ color: "#c049a7" }}></span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentInfoMini;
