import { makeObservable, observable } from "mobx";
import { Extra } from "../interfaces/CleaningInterfaces";

export default class MobxExtraItem {
	// Categories observable
	id: string | number;
	name: string;

	constructor(extra: Extra) {
		makeObservable(this, {
			name: observable,
			id: observable,
		});

		this.id = extra.id;
		this.name = extra.name;
	}
}
