// import Airtable from "airtable"
const Airtable = require("airtable");
Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: "keyuLeV7Fozw0ms0k",
  noRetryIfRateLimited: 300000,
});

const base = Airtable.base("appc7s1iCJwOX0J7Z");

export default base;
